import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Calendar from 'react-calendar';
import { Col, Row, Button, Label, FormGroup, Input } from 'reactstrap';
import axios from "axios";
import './status.css';
import Header from '../Header/index'
import Loading from '../Loading/index'
//import Home from '../Home/index';
import Swal from 'sweetalert2';
import ReactImageVideoLightbox from 'react-image-video-lightbox';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const skipEvent = ['2019-12-30','2019-12-31','2020-01-01','2020-01-02','2020-01-03','2020-01-04'];

const date_delivery = [];
const date_delivery_more = [];

const liff = window.liff;

export default class OrderStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            displayName: '',
            userId: process.env.REACT_APP_DEFAULT_LINE_ID,
            pictureUrl: '',
            statusMessage: '',
            flagDisplay: false,
            userType: '',
            userData: '',
            yearSelect: '',
            monthSelect: '',
            daySelect: '',
            flagRegister: false,
            //step: 'viewAll',
            step: 'default',
            value: new Date(),
            dateSelected: new Date(),
            shippingData: '',
            detailData: '',
            userAddress:'',
            shipping_id: '',
            redirectOrder: false,
            lightboxOpen: false
        };
        this.initialize = this.initialize.bind(this);
        this.closeApp = this.closeApp.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.onClick = this.onClick.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.getShipping = this.getShipping.bind(this);
        this.getDetail = this.getDetail.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.redirectToOrder = this.redirectToOrder.bind(this);
        this.lightboxOpen = this.lightboxOpen.bind(this);
    }

    componentDidMount() {
        //window.addEventListener('load', this.initialize);
        this.initialize();

        if (process.env.NODE_ENV !== 'production') {
            //for test
            this.getUsers();
        }
    }

    async initialize() {
        // liff.init(async (data) => {
        //     let profile = await liff.getProfile();
        //     this.setState({
        //         displayName: profile.displayName,
        //         userId: profile.userId,
        //         pictureUrl: profile.pictureUrl,
        //         statusMessage: profile.statusMessage,
        //     });
        //     this.getUsers();
        // });
        const liffid = '1607959730-Ad2N1lYm';
        await liff.init({ liffId: `${liffid}` }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            let profile = await liff.getProfile();
            // this.setState({
            //     name: getProfile.displayName,
            //     userLineID: getProfile.userId,
            //     pictureUrl: getProfile.pictureUrl,
            // });
            this.setState({
                displayName: profile.displayName,
                userId: profile.userId,
                pictureUrl: profile.pictureUrl,
                statusMessage: profile.statusMessage,
                //flagDisplay: true
            });
            //alert(profile.userId);
            this.getUsers();
        }else{
            liff.login();
        }
    }

    closeApp(event) {
        liff.sendMessages([{
            type: 'text',
            text: this.state.userId
        }]).then(() => {
            liff.closeWindow();
        });
    }

    redirectToOrder(){
        console.log('click');
        this.setState({
            redirectOrder: true
        });
    }

    lightboxOpen(value){
        console.log(value);
        this.setState({
            lightboxOpen: value
        })
    }

    async getShipping(){
        const userData = this.state.userData;
        const userToken = userData.customer_token;
        //console.log(userToken);
        const api = process.env.REACT_APP_API_URL + 'GetShipping/' + userToken;

        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
        .then(res => {
            //console.log(res.data);
            let shippingData = res.data;
            //console.log(shippingData);

            this.setState({
                shippingData: shippingData
            });


            shippingData.map((item, key) =>
                date_delivery.push(item.shipping_date)
            );

            //console.log(date_delivery);

            //date_delivery_more
            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
            let findDup = findDuplicates(date_delivery);

            findDup.map((item, key) => {
                let totalDup = this.getOccurrence(date_delivery, item);
                return date_delivery_more.push(item+'#'+totalDup);
            });

            this.changeStep('reload');

        });
    }

    //find dup array
    getOccurrence(array, value) {
        return array.filter((v) => (v === value)).length;
    }

    async getUsers() {
        const line_user_id = this.state.userId
        const api = process.env.REACT_APP_API_URL + 'LineGetUsers/' + line_user_id;
        //alert(api);
        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then(res => {
                //console.log(res.data);
                let userData = res.data;
                //console.log(userData[0].status);

                if (userData[0].status === 'fail') {
                    this.setState({
                        flagRegister: true,
                        flagDisplay: true
                    });
                } else {
                    var dateSelect = userData[0].customer_dob.split('-');
                    const yearSelect = dateSelect[0];
                    const monthSelect = dateSelect[1];
                    const daySelect = dateSelect[2];

                    this.setState({
                        userType: 'current',
                        userData: userData[0],
                        yearSelect: yearSelect,
                        monthSelect: monthSelect,
                        daySelect: daySelect,
                        flagDisplay: true
                    });

                    this.getShipping();
                    this.getAddress();

                    //console.log(yearSelect);
                }
            });
    }

    changeStep(step, shipping_id){

        if (typeof shipping_id !== 'undefined'){
            //console.log(shipping_id);
            this.setState({
                shipping_id: shipping_id
            });
        }
        
        this.setState({
            step: step
        });
    }

    onClick(date){
        let getYear = date.getFullYear();
        let getMonth = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1):(date.getMonth() + 1);
        let getDay = date.getDate() < 10 ? '0'+date.getDate():date.getDate();

        const dateFormat = getYear+'-'+getMonth+'-'+getDay;
        
        if(date_delivery.indexOf(dateFormat) >= 0){
            this.setState({
                step: 'viewDetail',
                dateSelected: dateFormat,
                flagDisplay: true
            });
            
        }

        this.getDetail(dateFormat);
    }

    async getDetail(dateFormat){
        const userData = this.state.userData;
        const userToken = userData.customer_token;
        const dateSelected = dateFormat;
        //console.log(dateSelected);
        const api = process.env.REACT_APP_API_URL + 'GetOrderDetail/' + userToken+'?shipping_date='+dateSelected;

        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
        .then(res => {
            //console.log(res.data);
            let detailData = res.data;
            //console.log(detailData);
            this.setState({
                detailData: detailData
            });
            

            //this.changeStep('reload');

        });
    }

    async getAddress() {
        const userData = this.state.userData;
        const userToken = userData.customer_token;
        //console.log(dateSelected);
        const api = process.env.REACT_APP_API_URL + 'GetAddress/'+userToken;

        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
        .then(res => {
            //console.log(res.data);
            let userAddress = res.data;
            //console.log(userAddress);
            this.setState({
                userAddress: userAddress
            });
            

            //this.changeStep('reload');

        });
    }

    render() {
        let displayStep;

        switch(this.state.step){
            case 'viewDetail': {
                const d = new Date(this.state.dateSelected);
                //document.write("The current month is " + monthNames[d.getMonth()]);
                displayStep = <ShowDetail 
                    dateSelected={this.state.dateSelected}
                    dateLabel={d.getDate()+' '+monthNames[d.getMonth()]+' '+d.getFullYear()}
                    nextStep={this.changeStep}
                    detailData={this.state.detailData}
                />
                break;
            }
            case 'changeAddress': {
                displayStep = <ChangeAddress 
                    nextStep={this.changeStep} 
                    shippingId={this.state.shipping_id}
                    userAddress={this.state.userAddress} 
                    userData={this.state.userData}
                />
                break;
            }
            case 'changeDate': {
                displayStep = <ChangeDate 
                    nextStep={this.changeStep} 
                    shippingId={this.state.shipping_id}
                    userData={this.state.userData} 
                    shippingData={this.state.shippingData}
                />
                break;
            }
            default: {
                displayStep = <ViewOrder 
                    onClick={this.onClick} 
                    findDelivery={findDelivery}
                    findDeliveryMore={findDeliveryMore}
                    findNumDelivery={findNumDelivery}
                    shippingData={this.state.shippingData} 
                    redirectPage={this.redirectToOrder}
                    lightboxOpen={this.lightboxOpen}
                />
               
                break;
            }
        }
        
        if (this.state.flagDisplay === true) {
            if(this.state.redirectOrder === true){
                return <Redirect to='/orders' />
            }

            if(this.state.flagRegister === true){
                return <Redirect to='/?callback=orderstatus' />
            }else{
                return (
                    <div>
                        <div className="home">
                            <Header />
        
                            <div className="status">
                                {displayStep}
                            </div>
                        </div>
                    
                        {
                            this.state.lightboxOpen &&
                            <ReactImageVideoLightbox
                                data={[
                                { url: 'https://www.healthytastydelivery.com/onlineshop/assets/tutorial.mp4', type: 'video', altTag: 'placeholder video' }]}
                                startIndex={0}
                                showResourceCount={true}
                                onCloseCallback={() => { this.setState({ lightboxOpen: false }); }} />
                        }
                    </div>
                );
            }
        } else {
            return (
                <div className="home">
                    <Header />

                    <div className="status">
                        <Loading />
                    </div>
                </div>
            );
        }
    }
}

const findDelivery = (date) => {
    let getYear = date.getFullYear();
    let getMonth = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1):(date.getMonth() + 1);
    let getDay = date.getDate() < 10 ? '0'+date.getDate():date.getDate();

    const dateFormat = getYear+'-'+getMonth+'-'+getDay;
    if(date_delivery.indexOf(dateFormat) >= 0){
        return true;
    }else{
        return false;
    }
};

const findDeliveryMore = (date) => {
    let getYear = date.getFullYear();
    let getMonth = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1):(date.getMonth() + 1);
    let getDay = date.getDate() < 10 ? '0'+date.getDate():date.getDate();

    let dateOfDelivery=[],numOfDelivery = [];

    date_delivery_more.forEach(function (item, index, arr) {
        item.split('#').forEach(function (item, index, arr) {
            if (index % 2 === 0) {
                dateOfDelivery.push(item);
            } else {
                numOfDelivery.push(item);
            }
        });

    });

    const dateFormat = getYear+'-'+getMonth+'-'+getDay;
    if(dateOfDelivery.indexOf(dateFormat) >= 0){
        return true;
    }else{
        return false;
    }
};

const findNumDelivery = (date) => {
    let getYear = date.getFullYear();
    let getMonth = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1):(date.getMonth() + 1);
    let getDay = date.getDate() < 10 ? '0'+date.getDate():date.getDate();

    let dateOfDelivery=[],numOfDelivery = [];

    date_delivery_more.forEach(function (item, index, arr) {
        item.split('#').forEach(function (item, index, arr) {
            if (index % 2 === 0) {
                dateOfDelivery.push(item);
            } else {
                numOfDelivery.push(item);
            }
        });

    });

    const dateFormat = getYear+'-'+getMonth+'-'+getDay;
    if(dateOfDelivery.indexOf(dateFormat) >= 0){
        const index = dateOfDelivery.indexOf(dateFormat);
        return numOfDelivery[index];
    }else{
        return '';
    }
};

const ViewOrder = ({onClick, findDelivery, findDeliveryMore, findNumDelivery, shippingData, redirectPage, lightboxOpen}) => {

    const orderCourse = () => {
        if(shippingData.length){
            let lastKey = shippingData.length-1;
            let lastShipping = shippingData[lastKey];
            //console.log(lastShipping.shipping_date)
            const date1 = new Date(lastShipping.shipping_date);
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            //console.log(diffDays);

            if(diffDays <= 2){
                return <div>
                    <p className="" style={{ color: '#AED864'}}>Expire date in {diffDays} days</p>
                        
                    <Row className="">
                        <Col xs="3"></Col>
                        <Col xs="6">
                            <Button color="success" size="lg" block className="btnStyle" onClick={() => redirectPage()} style={{ marginTop: '5px', marginBottom: '10px'}}>ORDER NOW</Button>
                        </Col>
                        <Col xs="3"></Col>
                    </Row>
                </div>
            }else{
                return;
            }

        }else{
            return;
        }
        
    }
    
    return (
    <div>
        <Calendar
            onClickDay={onClick}
            calendarType="US"
            locale="en-US" 
            tileClassName={
                ({ date, view }) => {
                    if(view === 'month' && findDelivery(date)){
                        if(findDeliveryMore(date)){
                            return 'eventDeliveryMore';
                        }else{
                            return 'eventDelivery';
                        }
                        
                    }
                }
            }
            //tileContent={({ date, view }) => view === 'month' && date.getDay() === 1 ? <p className="numberofdelivery">{date.getDay()}</p> : null}
            tileContent={
                ({ date, view }) => {
                    if(view === 'month' && findNumDelivery(date) > 0){
                        return <p className="numberofdelivery">{findNumDelivery(date)}</p>
                    }
                }
            }
            tileDisabled={({activeStartDate, date, view }) => date.getDay() === 0}
        />
        <div className="text-info">
            <p className="text-muted">Click on date to see detail of your order</p>
            {orderCourse()}
            
            <h5 className="text-muted">How to change delivery date</h5>
            <p className="text-muted p-left">To change date, time and place of delivery, please do it 2 days in advance of the delivery day.</p>
            <p className="text-muted p-left">หากต้องการเปลี่ยนวัน เวลา หรือที่อยู่สำหรับการจัดส่ง โปรดทำการเปลี่ยนแปลงล่วงหน้า 2 วันก่อนวันส่งจริง</p>
            <Button color="success" size="lg" block className="btnStyle" onClick={() => { window.location='https://www.healthytastydelivery.com/onlineshop/assets/tutorial.mp4' }}>See Tutorial</Button>
        </div>
    </div>
)};

const ShowDetail = ({dateSelected ,dateLabel, nextStep, detailData}) => {
    //console.log(detailData.length);
    const OrderDetail = [];
    for (let i = 0; i < detailData.length; i++) { 
        //console.log(detailData[i]);
        OrderDetail.push(detailData[i]);
    }
    //console.log(OrderDetail);
    
    const orderData = OrderDetail.map((item, key) => {

        const date1 = new Date(item.shipping_date);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        //console.log(diffDays);
        return <div key={key}>
            <Row className="ShowDetail">
                <Col xs="5"><h5>ORDER</h5></Col>
                <Col xs="7">{item.courses_description}</Col>
            </Row>
            <Row className="ShowDetail">
                <Col xs="5"><h5>TYPE</h5></Col>
                <Col xs="7">{item.courses_meal} Meal</Col>
            </Row>
            <Row className="ShowDetail">
                <Col xs="5"><h5>DELIVERY</h5></Col>
                <Col xs="7">{item.customer_shipping_address}</Col>
            </Row>
            <Row className="ShowDetail">
                <Col xs="5"><h5>TIME</h5></Col>
                <Col xs="7">{item.shipping_time}</Col>
            </Row>
            <hr />
            {
                diffDays > 2 ?<Row style={{marginBottom: '20px'}}>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('changeDate', item.shipping_id)}>Change Date</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('changeAddress', item.shipping_id)}>Change Address</Button>
                </Col>
            </Row>:<Row style={{marginBottom: '20px'}}>
                <p>If you would like to change address or time of delivery you can contact our staff to change in the Line Chat 
                หากคุณลูกค้าต้องการเปลี่ยนที่อยู่หรือเวลาจัดส่งสามารถติดต่อพนักงานของเราผ่านไลน์ได้เลยนะคะ</p>
            </Row>
            }
        </div>
    });

    /*detailData.forEach((item) => {
        console.log(item);
    });*/

    /*detailData.map((item, key) => {
        console.log(item.customer_shipping_address);
    });*/

    
    //console.log(diffDays);
    return (
    <div>
        <h3>{dateLabel}</h3>
        <hr />
        {orderData}
        
        <Row style={{marginTop: '20px', marginBottom: '20px'}}>
          <Col>
            <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('default')}>Back</Button>
          </Col>
        </Row>
    </div>
)};


const ChangeDate = ({ nextStep, shippingId, userData, shippingData }) => {
    const shipping_arr = [];
    for (let i = 0; i < shippingData.length; i++) {
        //console.log(shippingData[i].shipping_id);
        shipping_arr.push(shippingData[i]);
    }
    //console.log(shipping_arr);

    const found = shipping_arr.find(function(element) {
        //console.log(element.shipping_id);
        return element.shipping_id === shippingId;
    });
      
      //console.log(found);

    const d = new Date(found.shipping_date);
    let currentDay = d.getDate() < 10 ? '0'+d.getDate():d.getDate();
    let currentMonth = (d.getMonth()+1) < 10 ? '0'+(d.getMonth()+1):(d.getMonth()+1);
    let currentYear = d.getFullYear();

    //console.log(currentMonth);

    //let currentDay = function(){}

    let shipping_date = currentYear+'-'+currentMonth+'-'+currentDay;
    let shipping_time = found.shipping_time;

    const setDay = (dayValue) => {
        shipping_date = currentYear+'-'+currentMonth+'-'+dayValue;
    }

    const setMonth = (monthValue) => {
        shipping_date = currentYear+'-'+monthValue+'-'+currentDay;
    }

    const setYear = (yearValue) => {
        shipping_date = yearValue+'-'+currentMonth+'-'+currentDay;
    }

    const updateShippingTime = function(shipping_date, shipping_time){
        //console.log(shipping_date+' '+shipping_time);
        const currentDateTime = new Date();
        currentDateTime.setHours(7);
        currentDateTime.setMinutes(0);
        currentDateTime.setSeconds(0);
        const shippingDateTime = new Date(shipping_date);
        const shippingDay = shippingDateTime.getDay();
        const diffTime = Math.abs(shippingDateTime - currentDateTime);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //console.log(currentDateTime);
        //console.log(shippingDateTime);

        //const date1 = new Date(shipping_date);
        //const date2 = new Date();
        //const diffTime = Math.abs(date2 - date1);
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        //console.log(diffDays);

        if(shippingDateTime > currentDateTime && diffDays> 2){
            //console.log(shippingDay);
            if(shippingDay !== 0 && skipEvent.indexOf(String(shipping_date)) === -1){
                const userToken = userData.customer_token;
                //console.log(dateSelected);
                let data = {
                    shipping_id: shippingId,
                    shipping_type: 'datetime',
                    shipping_date: shipping_date,
                    shipping_time: shipping_time,
                    token: userToken
                };

                const api = process.env.REACT_APP_API_URL + 'UpdateShipping/';

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }

                axios.post(api, data, {
                    headers: headers
                })
                    .then(res => {
                        //Swal.fire('Oops...', 'Something went wrong!', 'error')
                        //console.log(res);
                        if (res.status === 200) {
                            let resData = res.data;
                            if(resData[0].status === 'fail'){
                                Swal.fire({
                                    title: 'Sorry!',
                                    text: resData[0].message,
                                    type: 'error',
                                    onClose: () => {
    
                                    }
                                });
                            }else{
                                Swal.fire({
                                    title: 'Success',
                                    text: 'Update data completed!',
                                    type: 'success',
                                    onClose: () => {
                                        nextStep('default');
                                        window.location.reload();
                                    }
                                });
                            }
                            
                        } else {
                            Swal.fire({
                                title: 'Sorry!',
                                text: 'Something went wrong!',
                                type: 'error',
                                onClose: () => {

                                }
                            });
                        }
                    });
            }else{
                Swal.fire({
                    title: 'Sorry!',
                    text: 'Shipping date out of delivery. Please try agian',
                    type: 'error',
                    onClose: () => {
    
                    }
                });
            }
        }else{
            Swal.fire({
                title: 'Sorry!',
                text: 'It is out of time to change date of tomorrow delivery. Please do it i  advance 2 days.',
                type: 'error',
                onClose: () => {

                }
            });
        }
    }

    return (
        <div>
            <h4>CHANGE DATE</h4>
            <Row form>
                <Col sd={4}>
                    <FormGroup>
                        <Input type="select" name="dob" id="dob" defaultValue={currentDay} onChange={(e) => setDay(e.target.value)}>
                            <DayNum />
                        </Input>
                    </FormGroup>
                </Col>
                <Col sd={4}>
                    <FormGroup>
                        <Input type="select" name="mob" id="mob" defaultValue={currentMonth} onChange={(e) => setMonth(e.target.value)}>
                            <MonthNum />
                        </Input>
                    </FormGroup>
                </Col>
                <Col sd={4}>
                    <FormGroup>
                        <Input type="select" name="yob" id="yob" defaultValue={currentYear} onChange={(e) => setYear(e.target.value)}>
                            <YearNum />
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <h4>CHANGE TIME</h4>
            <Row form>
                <Col>
                    <FormGroup>
                        <Input type="select" name="time" id="time" defaultValue={found.shipping_time} onChange={(e) => shipping_time = e.target.value}>
                            <DeliveryTime />
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('default')}>Back</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => updateShippingTime(shipping_date,shipping_time)}>Confirm</Button>
                </Col>
            </Row>
        </div>
    )
};

const ChangeAddress = ({ nextStep, shippingId, userAddress, userData }) => {
    //console.log(userAddress);

    const AddressDetail = [];
    for (let i = 0; i < userAddress.length; i++) {
        //console.log(detailData[i].shipping_id);
        AddressDetail.push(userAddress[i]);
    }

    let addressId = ''

    let updateAddress = function (shippingId, addressId) {
        //console.log(shippingId + ' ' + addressId);

        if (shippingId === '' || addressId === '') {
            Swal.fire({
                title: 'Oops...',
                text: 'Something went wrong!',
                type: 'error',
                onClose: () => {

                }
            });
        } else {
            //const userData = this.state.userData;
            const userToken = userData.customer_token;
            //console.log(dateSelected);
            let data = {
                shipping_id: shippingId,
                shipping_type: 'address',
                customer_shipping_id: addressId,
                token: userToken
            };

            const api = process.env.REACT_APP_API_URL + 'UpdateShipping/';

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }

            axios.post(api, data, {
                headers: headers
            })
                .then(res => {
                    //Swal.fire('Oops...', 'Something went wrong!', 'error')
                    if (res.status === 200) {
                        let resData = res.data;
                        if(resData[0].status === 'fail'){
                            Swal.fire({
                                title: 'Sorry!',
                                text: resData[0].message,
                                type: 'error',
                                onClose: () => {

                                }
                            });
                        }else{
                            Swal.fire({
                                title: 'Success',
                                text: 'Update data completed!',
                                type: 'success',
                                onClose: () => {
                                    nextStep('default');
                                    window.location.reload();
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {

                            }
                        });
                    }
                });
        }
    }

    //console.log(OrderDetail);

    const addressData = AddressDetail.map((item, key) => {
        return <FormGroup key={item.customer_shipping_id} className="text-muted addressList">
            <Label check>
                <Input type="radio" name="customer_shipping_id" value={item.customer_shipping_id} key={item.customer_shipping_id} onClick={() => addressId = item.customer_shipping_id} />
                {item.customer_shipping_address}
            </Label>
        </FormGroup>
    });

    return (
        <div>
            <FormGroup>
                <legend>CHANGE ADDRESS</legend>
                {addressData}
            </FormGroup>
            <Row style={{ marginTop: '20px' }}>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('default')}>Back</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => updateAddress(shippingId, addressId)}>Confirm</Button>
                </Col>
            </Row>
        </div>
    )
};

const DayNum = () => {
    const dayArray = [];
    for (var i = 1; i <= 31; i++) {
        let dayFormat = i;
        if (dayFormat < 10) {
            dayFormat = '0' + dayFormat;
        }

        dayArray.push(<option key={i} value={dayFormat}>{dayFormat}</option>);

    }
    return dayArray;
};

const MonthNum = () => {
    const month = [];
    
    for (var i = 1; i <= 12; i++) {
        let monthFormat = i;
        if (monthFormat < 10) {
            monthFormat = '0' + monthFormat;
        }
        
        month.push(<option key={i} value={monthFormat}>{monthFormat}</option>);
    }
    return month;
}

const YearNum = () => {
    let currentYear = new Date().getFullYear();
    let LimitYear = currentYear + 1
    const year = [];
    for (var i = currentYear; i <= LimitYear; i++) {
        year.push(<option key={i} value={i}>{i}</option>);
    }
    return year;
}

const DeliveryTime = () => {
    const TimeOfDelivery = [
        <option key='1' value="05:00-05:30">05:00-05:30</option>,
        <option key='2' value="05:30-06:00">05:30-06:00</option>,
        <option key='3' value="06:00-06:30">06:00-06:30</option>,
        <option key='4' value="06:30-07:00">06:30-07:00</option>,
        <option key='5' value="07:00-07:30">07:00-07:30</option>,
        <option key='6' value="07:30-08:00">07:30-08:00</option>,
        <option key='7' value="08:00-08:30">08:00-08:30</option>,
        <option key='8' value="08:30-09:00">08:30-09:00</option>,
    ];
    return TimeOfDelivery;
};
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import Faq from './components/Faq';
import Orders from './components/Orders';
import OrderStatus from './components/OrderStatus';
import Tdee from './components/Tdee';
import OrderComplete from './components/OrderComplete';

//import history from "./history";

class App extends Component {

  render() {
    return (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/orders" component={Orders} />
          <Route path="/orderstatus" component={OrderStatus} />
          <Route path="/tdee" component={Tdee} />
          <Route path="/faq" component={Faq} />
          <Route path="/complete" component={OrderComplete} />
        </Switch>
    );
  }

}

export default App;

import React from 'react';
import { Redirect } from 'react-router-dom'
import queryString from 'query-string';
import Calendar from 'react-calendar';
import { Progress, Media, Col, Row, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import './orders.css';
import Header from '../Header/index'
import Loading from '../Loading/index'

import CalLevel from '../../assets/cal_level.png';
import Arrow from '../../assets/arrow.png';
import axios from "axios";
import Swal from 'sweetalert2'

const liff = window.liff;
var date_delivery = [];

const activity_array = ['No exercise or exercise less than 2 days per week', 'Walking', 'Yoga', 'Fitness', 'Running', 'Swimming', 'Boxing', 'Weight training', 'Cardio', 'Run marathon'];
const activity_list = activity_array.map((actList, i) =>
    <option key={i} value={actList}>{actList}</option>
);

const goal_array = ['Lose weight', 'Maintain weight', 'Gain weight'];
const courses_list = ['healthy', 'paleo', 'keto', 'fast', 'mother', 'recovery', 'vegetarian'];
const skipEvent = [
    '2020-12-28',
    '2020-12-29',
    '2020-12-30',
    '2020-12-31',
    '2021-01-01',
    '2021-01-02',
    '2021-01-03',
    '2021-04-12',
    '2021-04-13',
    '2021-04-14',
    '2021-04-15',
    '2021-04-16',
    '2021-04-17',
    '2021-04-18'
];

const goal_list = goal_array.map((goalList, i) =>
    <option key={i} value={goalList}>{goalList}</option>
);

export default class Orders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flagDisplay: false,
            displayName: '',
            userId: process.env.REACT_APP_DEFAULT_LINE_ID,
            //userId: '',
            pictureUrl: '',
            statusMessage: '',
            userData: '',
            yearSelect: '',
            monthSelect: '',
            daySelect: '',
            step: '',
            customer_fullname: '',
            customer_phone: '',
            customer_nickname: '',
            customer_height: '',
            customer_weight: '',
            customer_activity: 'No exercise or exercise less than 2 days per week',
            customer_goal: 'Lose weight',
            customer_sex: 'female',
            customer_bmr: '',
            customer_tdee: '',
            customer_recommended: '',
            flagRegister: false,
            flagWaiting: false,
            error: {
                weight: false,
                height: false
            },
            courseSelected: '',
            PlanSelected: '',
            mealSelected: '3',
            daySelected: '',
            numberOfMeal: '',
            numberOfDay: '',
            mealType: '',
            courseData: '',
            shippingData: '',
            userAddress: '',
            dateOfDelivery: '',
            customerShippingId: '',
            newCustomerAddress: '',
            shippingTime: '',
            paramCourseName: '',
            paramCoursePlan: '',
            paramCourseMeal: '',
            payment_token: '',
            payment_encode: '',
            payment_charge: ''
        }

        this.changeStep = this.changeStep.bind(this);
        this.initialize = this.initialize.bind(this);
        this.closeApp = this.closeApp.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.omisePay = this.omisePay.bind(this);
        this.setCourse = this.setCourse.bind(this);
        this.setKcalPlan = this.setKcalPlan.bind(this);
        this.setNumberOfMeal = this.setNumberOfMeal.bind(this);
        this.setNumberOfDay = this.setNumberOfDay.bind(this);
        this.setMealType = this.setMealType.bind(this);
        this.setCourseData = this.setCourseData.bind(this);
        this.setPreLoad = this.setPreLoad.bind(this);

        this.getShipping = this.getShipping.bind(this);
        this.getAddress = this.getAddress.bind(this);
        this.setDateOfDelivery = this.setDateOfDelivery.bind(this);

        this.setCustomerShipping = this.setCustomerShipping.bind(this);
        this.setNewCustomerAddress = this.setNewCustomerAddress.bind(this);
        this.setShippingTime = this.setShippingTime.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.submitOrders = this.submitOrders.bind(this);
        this.addAddress = this.addAddress.bind(this);
        this.checkStepWithParam = this.checkStepWithParam.bind(this);
    }

    componentDidMount() {
        //window.addEventListener('load', this.initialize);
        //alert('orders');
        let url = this.props.location.search;
        let params = queryString.parse(url);

        //console.log(params);

        this.initialize();

        if (process.env.NODE_ENV !== 'production') {
            //for test
            this.getUsers();
        }

        if(typeof params.name !== 'undefined'){
            if(courses_list.indexOf(params.name) > -1){
                this.setState({
                    courseSelected: params.name
                });
            }
            //this.changeStep('plan_select');
        }

        if(typeof params.plan !== 'undefined'){
            this.setState({
                planSelected: params.plan
            })
        }

        if(typeof params.meal !== 'undefined'){
            if(params.meal === '2' || params.meal === '3'){
                this.setState({
                    mealSelected: params.meal
                })
            }else{
                this.setState({
                    mealSelected: '3'
                })
            }
        }

        if(typeof params.day !== 'undefined'){
            if(params.day === '5' || params.day === '6' || params.day === '20' || params.day === '26'){
                this.setState({
                    daySelected: params.day
                })
            }
        }
    }

    checkStepWithParam(){
        if(this.state.courseSelected !== ''){
            this.changeStep('plan_select');
        }
    }

    async submitOrders(payment_type){
        /*$utoken = $this->post( 'token' );
        $payment_type = $this->post('payment_type');
        $customer_shipping_id = $this->post('customer_shipping_id');
        $courses_id = $this->post('courses_id');
        $shipping_time = $this->post('shipping_time');
        $shipping_date = $this->post('shipping_date');
        $orders_total = $this->post('orders_total');*/
        const userData = this.state.userData;
        const courseData = this.state.courseData;

        let data = {
            token: userData.customer_token,
            payment_type: payment_type,
            customer_shipping_id: this.state.customerShippingId,
            courses_id: courseData.courses_id,
            shipping_time: this.state.shippingTime,
            shipping_date: this.state.dateOfDelivery,
            orders_total: courseData.courses_price,
            payment_token: this.state.payment_token,
            payment_encode: this.state.payment_encode,
            payment_charge: this.state.payment_charge
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }

        const api = process.env.REACT_APP_API_URL + 'CreateOrders/';
        await axios.post(api, data, {
            headers: headers
        })
            .then(res => {
                //Swal.fire('Oops...', 'Something went wrong!', 'error')
                //console.log(res.status);
                //console.log(res.data);
            });
    }

    omisePay() {
        const courseData = this.state.courseData;
        const { OmiseCard } = window;

        const PayUserData = this.state.userData;
        const PayCourseData = this.state.courseData;

        OmiseCard.open({
            publicKey: process.env.REACT_APP_OMISE_PUBLIC_KEY,
            image: 'https://www.healthytastydelivery.com/onlineshop/assets/new_frontend/img/logo-hd.png',
            frameLabel: 'Healthy Tasty Delivery',
            frameDescription: courseData.courses_description,
            amount: courseData.courses_price + '00',
            currency: 'thb',
            submitFormTarget: null,
            onCreateTokenSuccess: (token) => {
                const encodeString = `${PayUserData.customer_token}||credit||${this.state.customerShippingId}||${PayCourseData.courses_id}||${this.state.shippingTime}||${this.state.dateOfDelivery}||${PayCourseData.courses_price}`
                const DataEncode = window.btoa(encodeString);
                const data = {
                    card_token: token,
                    amount: courseData.courses_price + '00',
                    description: courseData.courses_description+' for '+PayUserData.customer_fullname,
                    currency: 'thb',
                    return_uri: `${process.env.REACT_APP_OMISE_CALLBACK}?token=${DataEncode}` 
                }

                this.setState({
                    payment_token: token,
                    payment_encode: DataEncode
                });

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }

                const api = process.env.REACT_APP_API_URL + 'PaymentOmise/';
                axios.post(api, data, {
                    headers: headers
                }).then(res => {
                    //console.log(res.status);
                    //console.log(res);
                    const resData = res.data;
                    if (res.status === 200) {
                        this.setState({
                            payment_charge: resData.id
                        });

                        if (resData.status === 'successful') {
                            this.submitOrders('credit');
                            this.changeStep('card_select');

                        } else if (resData.status === 'pending') {
                            this.submitOrders('credit_confirm');
                            Swal.fire({
                                title: 'Order Comfirm',
                                text: 'Please confirm your payment',
                                type: 'info',
                                buttons: ["Confirm"],
                                onClose: () => {
                                    //this.setState({ flagDisplay: true });
                                    
                                    if (process.env.NODE_ENV !== 'production') {
                                        window.location.href = resData.authorize_uri;
                                    }else{
                                        liff.closeWindow();
                                        liff.openWindow({
                                            url: resData.authorize_uri,
                                            external: true
                                        });
                                    }
                                }
                            });

                            
                            
                        }else {
                            Swal.fire({
                                title: 'Oops...',
                                text: 'Something went wrong!',
                                type: 'error',
                                onClose: () => {
                                    //this.setState({ flagDisplay: true });
                                }
                            });
                        }
                    } else {
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {
                                //this.setState({ flagDisplay: true });
                            }
                        });
                    }
                });

            },
            onFormClosed: () => {
                /* Your handler when form was closed */
            },
        })

        OmiseCard.attach();
    }

    async initialize() {
        // liff.init(async (data) => {
        //     let profile = await liff.getProfile();
        //     this.setState({
        //         displayName: profile.displayName,
        //         userId: profile.userId,
        //         pictureUrl: profile.pictureUrl,
        //         statusMessage: profile.statusMessage,
        //         //flagDisplay: true
        //     });
        //     //alert(profile);
        //     this.getUsers();
        // });
        const liffid = '1607959730-zY56xyK4';
        await liff.init({ liffId: `${liffid}` }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            let profile = await liff.getProfile();
            // this.setState({
            //     name: getProfile.displayName,
            //     userLineID: getProfile.userId,
            //     pictureUrl: getProfile.pictureUrl,
            // });
            this.setState({
                displayName: profile.displayName,
                userId: profile.userId,
                pictureUrl: profile.pictureUrl,
                statusMessage: profile.statusMessage,
                //flagDisplay: true
            });
            //alert(profile.userId);
            this.getUsers();
        }else{
            liff.login();
        }
    }

    async getAddress() {
        const userData = this.state.userData;
        const userToken = userData.customer_token;
        const api = process.env.REACT_APP_API_URL + 'GetAddress/' + userToken;
        //console.log(userData);
        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then(res => {
                let userAddress = res.data;
                this.setState({
                    userAddress: userAddress
                });

            });
    }

    async getShipping() {
        const userData = this.state.userData;
        const userToken = userData.customer_token;
        const api = process.env.REACT_APP_API_URL + 'GetShipping/' + userToken;

        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then(res => {
                let shippingData = res.data;
                this.setState({
                    shippingData: shippingData
                });

                //console.log(shippingData);

                shippingData.map((item, key) =>
                    date_delivery.push(item.shipping_date)
                );

                //console.log('date_delivery '+date_delivery);
                
            });
    }

    async getUsers() {
        const line_user_id = this.state.userId

        if(line_user_id !== ''){
            const api = process.env.REACT_APP_API_URL + 'LineGetUsers/' + line_user_id;
            //console.log(line_user_id);
            await axios.get(api, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
            })
            .then(res => {
                //console.log(res.data);
                const userData = res.data;

                if (userData[0].status === 'fail') {
                    //history.push('/');
                    this.setState({
                        flagRegister: true,
                        flagDisplay: true
                    });
                } else {
                    var dateSelect = userData[0].customer_dob.split('-');
                    const yearSelect = dateSelect[0];
                    const monthSelect = dateSelect[1];
                    const daySelect = dateSelect[2];

                    let customer_goal = userData[0].customer_goal;

                    if(customer_goal === '' || customer_goal === 'Be healthy'){
                        customer_goal = 'Maintain weight'
                    }

                    this.setState({
                        userType: 'current',
                        userData: userData[0],
                        yearSelect: yearSelect,
                        monthSelect: monthSelect,
                        daySelect: daySelect,
                        customer_fullname: userData[0].customer_fullname,
                        customer_phone: userData[0].customer_phone,
                        customer_nickname: userData[0].customer_nickname === '' ? userData[0].customer_fullname:userData[0].customer_nickname,
                        customer_height: userData[0].customer_height,
                        customer_weight: userData[0].customer_weight,
                        customer_activity: userData[0].customer_activity,
                        customer_goal: customer_goal,
                        customer_sex: userData[0].customer_sex,
                        flagDisplay: true
                    });

                    this.getShipping();
                    this.getAddress();
                    this.checkStepWithParam();
                }
            });
        }
    }

    async updateUser() {
        let yearSelect = this.state.yearSelect;
        let monthSelect = this.state.monthSelect;
        let daySelect = this.state.daySelect;

        let customer_dob = yearSelect + '-' + monthSelect + '-' + daySelect;

        let data = {
            customer_line_id: this.state.userId,
            customer_fullname: this.state.customer_fullname,
            customer_nickname: this.state.customer_nickname,
            customer_phone: this.state.customer_phone,
            customer_weight: this.state.customer_weight,
            customer_height: this.state.customer_height,
            customer_activity: this.state.customer_activity,
            customer_goal: this.state.customer_goal,
            customer_dob: customer_dob,
            customer_sex: this.state.customer_sex,
            customer_line_name: this.state.displayName
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }

        const api = process.env.REACT_APP_API_URL + 'LineUpdateUsers/';
        await axios.post(api, data, {
            headers: headers
        })
            .then(res => {
                //Swal.fire('Oops...', 'Something went wrong!', 'error')
                //console.log(res.status);
                //console.log(res.data);
            });
    }

    async addAddress() {
        const { value: text } = await Swal.fire({
            title: 'Please fill your address',
            input: 'textarea',
            inputPlaceholder: 'Type your message here...',
            inputAttributes: {
                'aria-label': 'Type your message here'
            },
            showCancelButton: true
        })

        if (text) {
            const userData = this.state.userData;
            const userToken = userData.customer_token;

            //Swal.fire(text);
            let data = {
                token: userToken,
                customer_shipping_address: text
            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }

            const api = process.env.REACT_APP_API_URL + 'GetAddress/';

            axios.post(api, data, {
                headers: headers
            }).then(res => {
                if (res.status === 200) {

                    let updateRes = res.data;
                    if (updateRes.length === 0) {
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {

                            }
                        });
                    }
                } else {

                    Swal.fire({
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        type: 'error',
                        onClose: () => {

                        }
                    });
                }
            });
            this.getAddress();
        }
    }

    setCourse(courseName) {
        //alert(courseName);
        this.setState({
            courseSelected: courseName
        });
    }

    setKcalPlan(getKcalPlan) {
        this.setState({
            PlanSelected: getKcalPlan
        });
    }

    setNumberOfMeal(getNumberOfMeal) {
        this.setState({
            numberOfMeal: getNumberOfMeal
        });
    }

    setNumberOfDay(getNumberOfDay) {
        this.setState({
            numberOfDay: getNumberOfDay
        });
    }

    setMealType(getMealType) {
        this.setState({
            mealType: getMealType
        });
    }

    setCourseData(CourseData) {
        //console.log(CourseData[0]);
        this.setState({
            courseData: CourseData[0]
        });
    }

    setDateOfDelivery(deliveryDate) {
        this.setState({
            dateOfDelivery: deliveryDate
        });
    }

    setCustomerShipping(customerShippingId) {
        this.setState({
            customerShippingId: customerShippingId
        });
    }

    setNewCustomerAddress(newCustomerAddress) {
        this.setState({
            newCustomerAddress: newCustomerAddress
        });
    }

    setShippingTime(shippingTime) {
        this.setState({
            shippingTime: shippingTime
        });
    }

    setPreLoad(flag) {
        this.setState({
            flagDisplay: flag
        });
    }

    handleChange(event) {
        //this.setState({value: event.target.value});
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'yob') {
            this.setState({ yearSelect: event.target.value });
        }
        //console.log(event.target.value);
    }

    closeApp(event) {
        liff.closeWindow();
    }

    changeStep(step) {

        let bmr = '';
        let tdee = '';
        let recommended = '';
        let customer_fullname = this.state.customer_fullname;
        let customer_phone = this.state.customer_phone;
        let customer_nickname = this.state.customer_nickname;
        let customer_sex = this.state.customer_sex;
        let customer_height = this.state.customer_height;
        let customer_weight = this.state.customer_weight;
        let customer_activity = this.state.customer_activity;
        let customer_goal = this.state.customer_goal === '' ? 'Maintain weight':this.state.customer_goal;
        let custmer_yob = this.state.yearSelect;
        let currentYear = new Date().getFullYear();
        let customer_age = currentYear - custmer_yob;
        let nextStep = true;

        //console.log(customer_goal);

        if (customer_fullname === '') {
            nextStep = false;
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    fullname: true
                }
            }));
        }

        if (customer_nickname === '') {
            nextStep = false;
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    nickname: true
                }
            }));
        }

        if (customer_phone === '') {
            nextStep = false;
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    phone: true
                }
            }));
        }

        if (customer_weight === '' || customer_weight === 0 || customer_weight === '0') {
            nextStep = false;
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    weight: true
                }
            }));
        }

        if (customer_height === '' || customer_height === 0 || customer_height === '0') {
            nextStep = false;
            this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    height: true
                }
            }));
        }

        if (!nextStep) {
            return;
        }

        if (customer_sex === 'male') {
            bmr = (10 * parseInt(customer_weight)) + (6.25 * parseInt(customer_height)) - (5 * parseInt(customer_age)) + 5;
        } else {
            bmr = (10 * parseInt(customer_weight)) + (6.25 * parseInt(customer_height)) - (5 * parseInt(customer_age)) - 161;
        }
        //'No exercise', 'Walking', 'Yoga', 'Fitness', 'Running', 'Swimming', 'Boxing', 'Weight training', 'Cardio', 'Run mararhon'
        switch (customer_activity) {
            default: {
                tdee = bmr * 1.2;
                break;
            }
            case 'Walking': {
                tdee = bmr * 1.25;
                break;
            }
            case 'Yoga': {
                tdee = bmr * 1.3;
                break;
            }
            case 'Fitness': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Running': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Swimming': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Boxing': {
                tdee = bmr * 1.55;
                break;
            }
            case 'Weight training': {
                tdee = bmr * 1.55;
                break;
            }
            case 'Cardio': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Run mararhon': {
                tdee = bmr * 1.55;
                break;
            }
        }
        //alert(tdee);
        //'Lose weight', 'Be healthy', 'Gain weight'
        let recommended_percent = 100;
        switch (customer_goal) {
            default: {
                //recommended = (bmr + tdee) / 2;
                recommended = tdee - 500;
                recommended_percent = 0;
                break;
            }
            case 'Maintain weight': {
                recommended = tdee;
                recommended_percent = 45;
                break;
            }
            case 'Gain weight': {
                recommended = tdee + 300;
                recommended_percent = 100;
                break;
            }
        }

        //cal bar
        //let min = 800;
        //let max = recommended;

        /*let recommended_percent = 100;
        let tdee_percent = Math.round((tdee * 100) / recommended) - 30;
        if (tdee > recommended) {
            //max = tdee;
            if(customer_goal === 'Lose weight'){
                recommended_percent = 35;
            }else{
                recommended_percent = Math.round((recommended * 100) / tdee);
            }
            tdee_percent = 100;
        }*/
        //let recommended_percent = 100;
        let tdee_percent = 100;

        this.setState({
            customer_bmr: Math.round(bmr),
            customer_tdee: Math.round(tdee),
            customer_recommended: Math.round(recommended),
            tdee_percent: tdee_percent,
            recommended_percent: recommended_percent,
            step: step
        });

        if (step === 'close_app') {
            this.closeApp();
        }

        //alert(recommended);

        /*this.setState({
            step: step
        });*/

        //alert(step);
    }

    DayofBirth() {
        const day = [];
        for (var i = 1; i <= 31; i++) {
            let dayFormat = i;
            if (dayFormat < 10) {
                dayFormat = '0' + dayFormat;
            }

            day.push(<option key={i} value={dayFormat}>{dayFormat}</option>);

        }
        return day;
    }

    MonthofBirth() {
        const month = [];
        for (var i = 1; i <= 12; i++) {
            let monthFormat = i;
            if (monthFormat < 10) {
                monthFormat = '0' + monthFormat;
            }

            month.push(<option key={i} value={monthFormat}>{monthFormat}</option>);
        }
        return month;
    }

    YearofBirth() {
        let currentYear = new Date().getFullYear();
        let startYear = currentYear - 80
        const year = [];
        for (var i = startYear; i <= currentYear; i++) {
            year.push(<option key={i} value={i}>{i}</option>);
        }
        return year;
    }

    render() {

        let displayStep;

        /*if (this.state.step === 1) {
            displayStep = courseSelect;
        } else {
            displayStep = calTdee;
        }*/

        switch (this.state.step) {
            /*case 1: {
                displayStep = <CustomerProfile
                    nextStep={this.changeStep}
                    DayofBirth={this.DayofBirth()}
                    MonthofBirth={this.MonthofBirth()}
                    YearofBirth={this.YearofBirth()}
                    UserData={this.state.userData}
                    handleChange={this.handleChange}
                />;
                break;
            }*/
            case 'cal_tdee': {
                this.updateUser();
                displayStep = <CalTdee
                    nextStep={this.changeStep}
                    closeApp={() => this.closeApp()}
                    customer_goal={this.state.customer_goal}
                    customer_bmr={this.state.customer_bmr}
                    customer_tdee={this.state.customer_tdee}
                    customer_recommended={this.state.customer_recommended}
                    tdee_percent={this.state.tdee_percent}
                    recommended_percent={this.state.recommended_percent}
                />;

                break;
            }
            case 'course_select': {
                displayStep = <CourseSelect nextStep={this.changeStep} setCourse={this.setCourse} />;
                break;
            }
            case 'plan_select': {
                displayStep = <PlanSelect
                    nextStep={this.changeStep}
                    customer_recommended={this.state.customer_recommended}
                    courseSelected={this.state.courseSelected}
                    planSelected={this.state.planSelected}
                    mealSelected={this.state.mealSelected}
                    daySelected={this.state.daySelected}
                    setKcalPlan={this.setKcalPlan}
                    setNumberOfMeal={this.setNumberOfMeal}
                    setNumberOfDay={this.setNumberOfDay}
                    setMealType={this.setMealType}
                    setCourseData={this.setCourseData}
                    setPreLoad={this.setPreLoad}
                    setDateOfDelivery={this.setDateOfDelivery}
                />;
                break;
            }
            case 'date_select': {
                displayStep = <OrderCalendar
                    nextStep={this.changeStep}
                    courseData={this.state.courseData}
                    date_delivery={date_delivery}
                    setDateOfDelivery={this.setDateOfDelivery}
                    dateOfDelivery={this.state.dateOfDelivery}
                />;
                break;
            }
            case 'time_select': {
                displayStep = <TimeSelect
                    nextStep={this.changeStep}
                    userAddress={this.state.userAddress}
                    setCustomerShipping={this.setCustomerShipping}
                    setNewCustomerAddress={this.setNewCustomerAddress}
                    setShippingTime={this.setShippingTime}
                    addAddress={this.addAddress}
                />;
                break;
            }
            case 'address_confirm': {
                const userAddress = this.state.userAddress;
                const dateOfDelivery = this.state.dateOfDelivery;
                const customerShippingId = this.state.customerShippingId;
                const newCustomerAddress = this.state.newCustomerAddress;
                const shippingTime = this.state.shippingTime;

                let showAddress = ''

                if (customerShippingId === '') {
                    showAddress = newCustomerAddress;
                } else {
                    var getShowAddress = userAddress.map((item, key) => {
                        if (item.customer_shipping_id === customerShippingId) {
                            return (item.customer_shipping_address);
                        }

                        return null;
                    });
                    showAddress = getShowAddress;
                }
                const dates = dateOfDelivery.map(function(x) { return new Date(x); })
                //console.log(dates);
                const earliest = new Date(Math.min.apply(null,dates));
                //console.log(earliest);

                const getDateLabel = new Date(earliest);
                const DateLabelDay = getDateLabel.getDate() < 10 ? '0' + getDateLabel.getDate() : getDateLabel.getDate();
                const DateLabelMonth = (getDateLabel.getMonth() + 1) < 10 ? '0' + (getDateLabel.getMonth() + 1) : (getDateLabel.getMonth() + 1);
                const DateLabelYear = getDateLabel.getFullYear();

                const DateLabel = DateLabelYear+'-'+DateLabelMonth+'-'+DateLabelDay;

                displayStep = <ConfirmAddress
                    nextStep={this.changeStep}
                    customerAddress={showAddress}
                    cutomerPhone={this.state.customer_phone}
                    shippingTime={shippingTime}
                    shippingStartDate={DateLabel}
                />;
                break;
            }
            case 'payment_select': {
                //this.omisePay();
                const getPrice = this.state.courseData;

                displayStep = <PaymentMethod
                    nextStep={this.changeStep}
                    payMethod={this.omisePay}
                    totalPayment={getPrice.courses_price}
                />;
                break;
            }
            case 'card_select': {
                displayStep = <ConfirmPaymentCard closeApp={() => this.closeApp()} />;
                break;
            }
            case 'bank_select': {
                this.submitOrders('transfer');
                displayStep = <ConfirmPaymentBank closeApp={() => this.closeApp()} />;
                break;
            }
            default: {
                displayStep = <CustomerProfile
                    nextStep={this.changeStep}
                    DayofBirth={this.DayofBirth()}
                    MonthofBirth={this.MonthofBirth()}
                    YearofBirth={this.YearofBirth()}
                    UserData={this.state.userData}
                    InputError={this.state.error}
                    handleChange={this.handleChange}
                />;
                break;
            }
        }

        //alert(this.state.flagDisplay + '-' + this.state.flagWaiting + '-' +this.state.flagRegister);
        

        if (this.state.flagDisplay === true) {
            if(this.state.flagRegister === true){
                let callbackUrl = `/?callback=orders&name=${this.state.courseSelected}&plan=${this.state.planSelected}&meal=${this.state.planSelected}`;
                return <Redirect to={callbackUrl} />
            }else{
                return (
                    <div className="home">
                        <Header />
    
                        <div className="orders">
                            {displayStep}
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className="home">
                    <Header />
                    
                    <div className="tdee">
                        <Loading />
                    </div>
                </div>
            );
        }


    }
}

const CustomerProfile = ({ nextStep, DayofBirth, MonthofBirth, YearofBirth, UserData, closeApp, InputError, handleChange }) => {
    const dob = UserData.customer_dob;
    const getBirthDay = new Date(dob);
    const BirthDay = getBirthDay.getDate() < 10 ? '0' + getBirthDay.getDate() : getBirthDay.getDate();
    const BirthMonth = (getBirthDay.getMonth() + 1) < 10 ? '0' + (getBirthDay.getMonth() + 1) : (getBirthDay.getMonth() + 1);
    const BirthYear = getBirthDay.getFullYear();
    //let InputValid = InputError;



    //console.log(dob);
    //console.log(InputValid.weight)
    return (
        <div>
            <h2 className="text-success">TDEE Test</h2>
            <hr />
            <FormGroup>
                <Input invalid={InputError.fullname} type="text" name="customer_fullname" id="customer_fullname" placeholder="Full name" defaultValue={UserData.customer_fullname} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Input invalid={InputError.nickname} type="text" name="customer_nickname" id="customer_nickname" placeholder="Nickname" defaultValue={UserData.customer_nickname} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Input invalid={InputError.phone} type="text" name="customer_phone" id="customer_phone" placeholder="Phone number" defaultValue={UserData.customer_phone} onChange={handleChange} />
            </FormGroup>
            <h5 className="text-muted">Birthday</h5>
            <Row form>
                <Col sd={4}>
                    <FormGroup>
                        <Input type="select" name="daySelect" id="daySelect" onChange={handleChange} defaultValue={BirthDay}>
                            {DayofBirth}
                        </Input>
                    </FormGroup>
                </Col>
                <Col sd={4}>
                    <FormGroup>
                        <Input type="select" name="monthSelect" id="monthSelect" onChange={handleChange} defaultValue={BirthMonth}>
                            {MonthofBirth}
                        </Input>
                    </FormGroup>
                </Col>
                <Col sd={4}>
                    <FormGroup>
                        <Input type="select" name="yearSelect" id="yearSelect" onChange={handleChange} defaultValue={BirthYear}>
                            {YearofBirth}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Input type="select" name="customer_sex" id="customer_sex" defaultValue={UserData.customer_sex} onChange={handleChange}>
                    <option value='female'>Female</option>
                    <option value='male'>Male</option>
                </Input>
            </FormGroup>

            <FormGroup>
                <Input invalid={InputError.weight} type="number" name="customer_weight" id="customer_weight" placeholder="Weight" defaultValue={UserData.customer_weight === '0' ? '' : UserData.customer_weight} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Input invalid={InputError.height} type="number" name="customer_height" id="customer_height" placeholder="Height" defaultValue={UserData.customer_height === '0' ? '' : UserData.customer_height} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
                <Input type="select" name="customer_activity" id="customer_activity" defaultValue={UserData.customer_activity} onChange={handleChange}>
                    {activity_list}
                </Input>
            </FormGroup>
            <FormGroup>
                <Input type="select" name="customer_goal" id="customer_goal" defaultValue={UserData.customer_goal} onChange={handleChange}>
                    {goal_list}
                </Input>
            </FormGroup>

            <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('cal_tdee')}>Next</Button>
        </div>
    )
};

/*const CalTdee = ({ nextStep, closeApp, customer_goal, customer_bmr, customer_tdee, customer_recommended, tdee_percent, recommended_percent }) => (
    <div>
        <h4 className="text-success">Recommendation for {customer_goal}</h4>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark', textAlign: 'center' }}>
            <Progress bar value={recommended_percent} color='white'></Progress>
            <Progress bar value="35" color='white'>
                <div style={{ color: '#000' }}>Calories suggested<br />{customer_recommended} kcal</div>
                <center><Media middle object data-src={Arrow} src={Arrow} alt="Cal" style={{ width: '30%' }} /></center>
            </Progress>
        </Progress>
        <Media>
            <Media middle href="#">
                <Media object data-src={CalLevel} src={CalLevel} alt="Generic placeholder image" />
            </Media>
        </Media>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark' }}>
            <Progress bar color='white' value="10" style={{ color: 'dark !important', backgroundColor: '#fff' }}>
                <div style={{ color: '#000' }}>BMR<br />{customer_bmr}<br />kcal</div>
            </Progress>
            <Progress bar value={tdee_percent} color='white'></Progress>
            <Progress bar color="white" value="10">
                <div style={{ color: '#000' }}>
                    TDEE<br />{customer_tdee}<br />kcal
                </div>
            </Progress>
        </Progress>
        <div className='textReccomended'>
            <p className='text-muted'>
                You should not consume less than your BMR as it will reduce your metabolism effectiveness
            </p>
            <p className='text-muted'>
                คุณไม่ควรรับแคลลอรี่ต่ำกว่าค่า BMR ของคุณเนื่องจากจะทำให้ประสิทธิภาพของระบบเผาพลาญน้อยลง
            </p>
        </div>
        <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('course_select')}>Next</Button>
    </div>
)*/

const CalTdee = ({ nextStep, closeApp, customer_goal, customer_bmr, customer_tdee, customer_recommended, tdee_percent, recommended_percent }) => (
    <div>
        <h4 className="text-success">Recommendation for <br/>{customer_goal}</h4>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark', textAlign: 'center' }}>
            <Progress bar value={recommended_percent} color='white'></Progress>
            <Progress bar value="10" color='white'>
                <center><Media middle object data-src={Arrow} src={Arrow} alt="Cal" style={{ width: '100%' }} /></center>
            </Progress>
        </Progress>
        <Media>
            <Media middle href="#">
                <Media object data-src={CalLevel} src={CalLevel} alt="Generic placeholder image" />
            </Media>
        </Media>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark' }}>
            <Progress bar color='white' value="12" style={{ color: 'dark !important', backgroundColor: '#fff' }}>
                <div style={{ color: '#000' }}>Lose<br />weight</div>
            </Progress>
            <Progress bar value={tdee_percent} color='white'>
                <div style={{ color: '#000' }}>Maintain<br />weight</div>
            </Progress>
            <Progress bar color="white" value="12">
                <div style={{ color: '#000' }}>
                    Gain<br />weight
                </div>
            </Progress>
        </Progress>
        
        <div>
            <h3 className="CourseSelection">Calories suggested</h3>
            <h3 className="CourseSelection">{customer_recommended}kcal/day</h3>
        </div>
        
        <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('course_select')}>Next</Button>
    </div>
)

const CourseSelect = ({ nextStep, setCourse }) => {
    const ClassDisplay = document.getElementsByClassName("CourseSelection");
    const course_type = document.getElementsByName('course_type');
    var getCourseSelected = '';

    const displayText = function (pId) {
        //console.log(ClassDisplay.length);
        //let showDetail = document.getElementsById(pId);
        for (let i = 0; i < ClassDisplay.length; i++) {
            ClassDisplay[i].style = 'display: none';
        }

        document.getElementById(pId).style = 'display: block';
        //alert(pId);


    }

    const courseSelected = function () {
        for (let i = 0, length = course_type.length; i < length; i++) {
            if (course_type[i].checked) {
                //console.log(course_type[i].value);
                getCourseSelected = course_type[i].value;

                //break;
            }
        }
    }

    const onChangeState = function () {
        if (getCourseSelected !== '') {
            nextStep('plan_select');
            setCourse(getCourseSelected);
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please choose your course',
                type: 'warning',
            });
        }
    }

    return (
        <div>
            <h3 className="text-success">Choose your course</h3>
            <hr />
            <div className="form">
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input invalid className="inputRadio" type="radio" name="course_type" value='healthy' onClick={() => courseSelected()} onChange={() => displayText('CourseHealthy')} />{' '}
                        Healthy Diet
                </Label>
                    <p id="CourseHealthy" className="CourseSelection" style={{ display: 'none' }}>
                        800 kcal per day<br />
                        1,000 kcal per day<br />
                        1,200  kcal per day<br />
                        1,500  kcal per day<br />
                    </p>
                </FormGroup>
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="course_type" value='paleo' onClick={() => courseSelected()} onChange={() => displayText('CoursePerfect')} />{' '}
                        Perfect Paleo
                </Label>
                    <p id="CoursePerfect" className="CourseSelection" style={{ display: 'none' }}>
                        1,700 kcal per day<br />
                        2,000  kcal per day<br />
                    </p>
                </FormGroup>
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="course_type" value='keto' onClick={() => courseSelected()} onChange={() => displayText('CourseKeto')} />{' '}
                        Keto Diet
                </Label>
                    <p id="CourseKeto" className="CourseSelection" style={{ display: 'none' }}>
                        1,200 kcal per day<br />
                        1,500  kcal per day<br />
                    </p>
                </FormGroup>
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="course_type" value='fast-metabolism' onClick={() => courseSelected()} onChange={() => displayText('CourseIntensive')} />{' '}
                        Intensive fast metabolism
                </Label>
                    <p id="CourseIntensive" className="CourseSelection" style={{ display: 'none' }}>
                        Calories vary from 1,000 - 1,200 up to each phase<br />
                    </p>
                </FormGroup>
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="course_type" value='mother' onClick={() => courseSelected()} onChange={() => displayText('CourseMother')} />{' '}
                        Mother Plan
                </Label>
                    <p id="CourseMother" className="CourseSelection" style={{ display: 'none' }}>
                        Phase 1 (0 - 3 Months)<br />
                        Phase 2 (4 - 6 Months)<br />
                        Phase 3 (7 - 9 Months)<br />
                        Phase 4 Post Pregnant<br />
                    </p>
                </FormGroup>
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="course_type" value='recovery' onClick={() => courseSelected()} onChange={() => displayText('CourseRecovery')} />{' '}
                        Recovery Plan
                </Label>
                    <p id="CourseRecovery" className="CourseSelection" style={{ display: 'none' }}>
                        Calories vary from 1,000 - 1,200 up to each phase<br />
                    </p>
                </FormGroup>
                <FormGroup check>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="course_type" value='vegetarian' onClick={() => courseSelected()} onChange={() => displayText('CourseVegetarian')} />{' '}
                        Vegetarian Diet
                </Label>
                    <p id="CourseVegetarian" className="CourseSelection" style={{ display: 'none' }}>
                        1,000 kcal per day<br />
                        1,200  kcal per day<br />
                        1,500  kcal per day<br />
                    </p>
                </FormGroup>
                <Button color="success" size="lg" block className="btnStyle" onClick={() => onChangeState()}>Next</Button>
            </div>
        </div>
    )
}


const PlanSelect = ({ 
    nextStep, customer_recommended, 
    courseSelected, setKcalPlan, 
    setNumberOfMeal, setNumberOfDay, 
    setMealType, setCourseData, 
    setPreLoad, setDateOfDelivery, 
    planSelected, mealSelected, daySelected
}) => {
    
    let planSelectedValue = planSelected;
    let mealSelectedValue = mealSelected;
    let daySelectedValue = daySelected;
    const kcalplan = document.getElementsByName('kcalplan');
    const numberOfMeal = document.getElementsByName('numberOfMeal');
    const mealType = document.getElementsByName('mealType');
    const numberOfDay = document.getElementsByName('numberOfDay');

    var planView = '';
    var getNumberOfMeal = '';
    var getKcalPlan = '';
    var getNumberOfDay = '';
    var getMealType = '';

    //console.log(mealSelectedValue);

    const changeKcalPlan = function () {
        for (let i = 0, length = kcalplan.length; i < length; i++) {
            if (kcalplan[i].checked) {
                //console.log(kcalplan[i].value);
                planSelectedValue = kcalplan[i].value;
                getKcalPlan = kcalplan[i].value;
                kcalplan[i].checked = true;
                //console.log(getKcalPlan);
                //break;
            }
        }
        //console.log(planSelectedValue);
    }

    const changeNumberOfMeal = function () {
        //console.log(numberOfMeal);
        let mealTypeArray = [];
        for (let i = 0, length = numberOfMeal.length; i < length; i++) {
            if (numberOfMeal[i].checked) {
                //console.log(numberOfMeal[i].value);
                getNumberOfMeal = numberOfMeal[i].value;
                //break;
            }
        }

        if (getNumberOfMeal === '3') {
            for (let i = 0, length = mealType.length; i < length; i++) {
                mealType[i].checked = true;
                mealTypeArray.push(mealType[i].value);
            }
        } else {
            for (let i = 0, length = mealType.length; i < length; i++) {
                if (mealType[i].value === 'B' || mealType[i].value === 'L') {
                    mealType[i].checked = true;
                    getNumberOfMeal = numberOfMeal[i].value;
                    mealTypeArray.push(mealType[i].value);
                } else {
                    mealType[i].checked = false;
                }
            }
        }

        getMealType = mealTypeArray.join();
        //console.log(getMealType);
    }

    const changeMealType = function (type) {
        let mealTypeArray = [];
        for (let i = 0, length = numberOfMeal.length; i < length; i++) {
            if (numberOfMeal[i].checked) {
                getNumberOfMeal = numberOfMeal[i].value;
            }
        }

        if (getNumberOfMeal === '3') {
            for (let i = 0, length = mealType.length; i < length; i++) {
                mealType[i].checked = true;
                mealTypeArray.push(mealType[i].value);
            }
        } else {
            for (let i = 0, length = mealType.length; i < length; i++) {
                if (mealType[i].value === type) {
                    mealType[i].checked = false;
                } else {
                    mealType[i].checked = true;
                    mealTypeArray.push(mealType[i].value);
                }
            }
        }
        getMealType = mealTypeArray.join();
        //console.log(getMealType);
    }

    const changeNumberOfDay = function () {
        for (let i = 0, length = numberOfDay.length; i < length; i++) {
            if (numberOfDay[i].checked) {
                //console.log(kcalplan[i].value);
                getNumberOfDay = numberOfDay[i].value;
                //console.log(getKcalPlan);
                //break;
            }
        }
    }

    const HealthyPlan = function(){
        return (
        <div>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="800" defaultChecked={planSelectedValue === '800' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    800 KCAL Plan
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1000" defaultChecked={planSelectedValue === '1000' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,000 KCAL Plan
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1200" defaultChecked={planSelectedValue === '1200' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,200 KCAL Plan
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1500" defaultChecked={planSelectedValue === '1500' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,500 KCAL Plan
                </Label>
            </FormGroup>
        </div>
    )}

    const PaleoPlan = () => (
        <div>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1700" defaultChecked={planSelectedValue === '1700' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,700 KCAL Plan
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="2000" defaultChecked={planSelectedValue === '2000' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    2,000 KCAL Plan
                </Label>
            </FormGroup>
        </div>
    )

    const KetoPlan = () => (
        <div>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1200" defaultChecked={planSelectedValue === '1200' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,200 KCAL Plan
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1500" defaultChecked={planSelectedValue === '1500' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,500 KCAL Plan
                </Label>
            </FormGroup>
        </div>
    )

    const FastPlan = () => (
        <div>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1200" defaultChecked={planSelectedValue === '1200' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,200 kcal
                </Label>
            </FormGroup>
        </div>
    )

    const MotherPlan = () => (
        <div>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="Phase1" defaultChecked={planSelectedValue === 'Phase1' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    Phase 1 (0 - 3 Months)
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="Phase2" defaultChecked={planSelectedValue === 'Phase2' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    Phase 2 (4 - 6 Months)
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="Phase3" defaultChecked={planSelectedValue === 'Phase3' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    Phase 3 (7 - 9 Months)
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="Phase4" defaultChecked={planSelectedValue === 'Phase4' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    Phase 4 Post Pregnant
                </Label>
            </FormGroup>
        </div>
    )

    const RecoveryPlan = () => (
        <div>
            After order our nutritionist will contact you for more details.<br />
            หลังการสั่งซื้อทางนักโภชนากรจะติดต่อคุณลูกค้าเพื่อสอบถามรายละเอียดเพิ่มเติมค่ะ
        </div>
    )

    const VegetarianPlan = () => (
        <div>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1000" defaultChecked={planSelectedValue === '1000' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,000 kcal
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1200" defaultChecked={planSelectedValue === '1200' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,200 kcal
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check className='orderLabel'>
                    <Input className="inputRadio" type="radio" name="kcalplan" defaultValue="1500" defaultChecked={planSelectedValue === '1500' ? true:false} onChange={() => changeKcalPlan()} />{' '}
                    1,500 kcal
                </Label>
            </FormGroup>
        </div>
    )

    const onChangeState = function () {
        //console.log(getNumberOfMeal);
        setPreLoad(false);
        let mealTypeArray = [];
        for (let i = 0, length = kcalplan.length; i < length; i++) {
            if (kcalplan[i].checked) {
                getKcalPlan = kcalplan[i].value;
            }
        }

        for (let i = 0, length = numberOfMeal.length; i < length; i++) {
            if (numberOfMeal[i].checked) {
                getNumberOfMeal = numberOfMeal[i].value;
            }
        }

        for (let i = 0, length = mealType.length; i < length; i++) {
            if (mealType[i].checked) {
                mealTypeArray.push(mealType[i].value);
            }
        }

        getMealType = mealTypeArray.join();

        for (let i = 0, length = numberOfDay.length; i < length; i++) {
            if (numberOfDay[i].checked) {
                getNumberOfDay = numberOfDay[i].value;
            }
        }

        //console.log(getNumberOfDay);

        //Check Course
        let data = {
            courseSelected: courseSelected,
            PlanSelected: getKcalPlan,
            NumberOfMeal: getNumberOfMeal,
            MealType: getMealType,
            NumberOfDay: getNumberOfDay
        };

        

        const api = process.env.REACT_APP_API_URL + 'GetCourse/';

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }

        if (courseSelected === 'fast-metabolism' || courseSelected === 'recovery') {
            if (getNumberOfMeal !== '' && getNumberOfDay !== '') {
                //nextStep('date_select');
                setKcalPlan(getKcalPlan);
                setNumberOfMeal(getNumberOfMeal);
                setNumberOfDay(getNumberOfDay);
                setMealType(getMealType);

                axios.post(api, data, {
                    headers: headers
                }).then(res => {
                    if (res.status === 200) {
                        //console.log(res.data);
                        setCourseData(res.data);
                        setPreLoad(true);
                        const courseRes = res.data;
                        //console.log(courseRes[0].courses_id);
                        if (courseRes.length === 0) {
                            Swal.fire({
                                title: 'Oops...',
                                text: 'Not found package, Please try agian',
                                type: 'error',
                                onClose: () => {

                                }
                            });
                        } else {
                            var courses_duration = parseInt(courseRes[0].courses_duration);

                            var max_dt = date_delivery[0],
                                max_dtObj = new Date(date_delivery[0]);
                            date_delivery.forEach(function (dt, index) {
                                if (new Date(dt) > max_dtObj) {
                                    max_dt = dt;
                                    max_dtObj = new Date(dt);
                                }
                            });

                            if (typeof max_dt === 'undefined') {
                                const currentDate = new Date();
                                currentDate.setDate(currentDate.getDate() + 2);
                                let getYear = currentDate.getFullYear();
                                let getMonth = (currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
                                let getDay = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

                                max_dt = getYear + '-' + getMonth + '-' + getDay;
                            } else {
                                const currentDate = new Date(max_dt);
                                currentDate.setDate(currentDate.getDate() + 1);
                                let getYear = currentDate.getFullYear();
                                let getMonth = (currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
                                let getDay = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

                                max_dt = getYear + '-' + getMonth + '-' + getDay;
                            }

                            //console.log(max_dt);
                            //console.log(courses_duration);
                            let new_delivery = [];
                            var i = 0;
                            do {
                                let cal_date = new Date(max_dt);
                                cal_date.setDate(cal_date.getDate() + i);
                                let getYear = cal_date.getFullYear();
                                let getMonth = (cal_date.getMonth() + 1) < 10 ? '0' + (cal_date.getMonth() + 1) : (cal_date.getMonth() + 1);
                                let getDay = cal_date.getDate() < 10 ? '0' + cal_date.getDate() : cal_date.getDate();

                                if (courses_duration === 6 || courses_duration === 26 || courses_duration === 7 || courses_duration === 14 || courses_duration === 28) {
                                    if (cal_date.getDay() !== 0) {
                                        if(skipEvent.indexOf(String(getYear + '-' + getMonth + '-' + getDay)) === -1){
                                            new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        }
                                    }
                                } else {
                                    if (cal_date.getDay() !== 0 && cal_date.getDay() !== 6) {
                                        //new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        if(skipEvent.indexOf(String(getYear + '-' + getMonth + '-' + getDay)) === -1){
                                            new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        }
                                    }
                                }

                                i++;
                            }
                            while (new_delivery.length < courses_duration);
                            //console.log('new date: '+new_delivery);
                            setDateOfDelivery(new_delivery);
                            nextStep('date_select');
                        }
                    } else {
                        setPreLoad(true);
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {

                            }
                        });
                    }
                });

            } else {
                setPreLoad(true);
                Swal.fire({
                    title: 'Warning!',
                    text: 'Please choose your course detail',
                    type: 'warning',
                });
            }
        } else {
            if (getNumberOfMeal !== '' && getNumberOfDay !== '' && getKcalPlan !== '') {
                //nextStep('date_select');
                setKcalPlan(getKcalPlan);
                setNumberOfMeal(getNumberOfMeal);
                setNumberOfDay(getNumberOfDay);
                setMealType(getMealType);

                axios.post(api, data, {
                    headers: headers
                }).then(res => {
                    if (res.status === 200) {
                        //console.log(res.data);
                        setCourseData(res.data);
                        setPreLoad(true);
                        const courseRes = res.data;
                        //console.log(courseRes.length);
                        if (courseRes.length === 0) {
                            Swal.fire({
                                title: 'Oops...',
                                text: 'Not found package, Please try agian',
                                type: 'error',
                                onClose: () => {

                                }
                            });
                        } else {
                            var courses_duration = parseInt(courseRes[0].courses_duration);

                            var max_dt = date_delivery[0],
                                max_dtObj = new Date(date_delivery[0]);
                            date_delivery.forEach(function (dt, index) {
                                if (new Date(dt) > max_dtObj) {
                                    max_dt = dt;
                                    max_dtObj = new Date(dt);
                                }
                            });

                            if (typeof max_dt === 'undefined') {
                                const currentDate = new Date();
                                currentDate.setDate(currentDate.getDate() + 2);
                                let getYear = currentDate.getFullYear();
                                let getMonth = (currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
                                let getDay = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

                                max_dt = getYear + '-' + getMonth + '-' + getDay;
                            } else {
                                const currentDate = new Date(max_dt);
                                currentDate.setDate(currentDate.getDate() + 1);
                                let getYear = currentDate.getFullYear();
                                let getMonth = (currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
                                let getDay = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

                                max_dt = getYear + '-' + getMonth + '-' + getDay;
                            }

                            //console.log(max_dt);
                            //console.log(courses_duration);
                            var new_delivery = [];
                            var i = 0;
                            do {
                                let cal_date = new Date(max_dt);
                                cal_date.setDate(cal_date.getDate() + i);
                                let getYear = cal_date.getFullYear();
                                let getMonth = (cal_date.getMonth() + 1) < 10 ? '0' + (cal_date.getMonth() + 1) : (cal_date.getMonth() + 1);
                                let getDay = cal_date.getDate() < 10 ? '0' + cal_date.getDate() : cal_date.getDate();

                                if (courses_duration === 6 || courses_duration === 26 || courses_duration === 24) {
                                    if (cal_date.getDay() !== 0) {
                                        //new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        if(skipEvent.indexOf(String(getYear + '-' + getMonth + '-' + getDay)) === -1){
                                            new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        }
                                    }
                                } else {
                                    if (cal_date.getDay() !== 0 && cal_date.getDay() !== 6) {
                                        //new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        if(skipEvent.indexOf(String(getYear + '-' + getMonth + '-' + getDay)) === -1){
                                            new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
                                        }
                                    }
                                }

                                i++;
                            }
                            while (new_delivery.length < courses_duration);
                            //console.log('new date: '+new_delivery);
                            setDateOfDelivery(new_delivery);
                            setPreLoad(true);
                            nextStep('date_select');
                        }
                    } else {
                        setPreLoad(true);
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {

                            }
                        });
                    }
                });

            } else {
                setPreLoad(true);
                Swal.fire({
                    title: 'Warning!',
                    text: 'Please choose your course detail',
                    type: 'warning',
                });
            }
        }
    }

    switch (courseSelected) {
        case 'healthy': {
            planView = <HealthyPlan />
            break;
        }
        case 'paleo': {
            planView = <PaleoPlan />
            break;
        }
        case 'keto': {
            planView = <KetoPlan />
            break;
        }
        case 'fast-metabolism': {
            planView = <FastPlan />
            break;
        }
        case 'mother': {
            planView = <MotherPlan />
            break;
        }
        case 'recovery': {
            planView = <RecoveryPlan />
            break;
        }
        case 'vegetarian': {
            planView = <VegetarianPlan />
            break;
        }
        default: {
            planView = <HealthyPlan />
            break;
        }
    }

    //console.log(mealSelectedValue);

    let dayDisplay = <div>
        <Row form>
            <Col sd={6}>
                <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="numberOfDay" value="5" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '5' ? true:false} />{' '}
                        5 days
                </Label>
                </FormGroup>
            </Col>
            <Col sd={6}>
                <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="numberOfDay" value="6" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '6' ? true:false} />{' '}
                        6 days
                </Label>
                </FormGroup>
            </Col>
        </Row>
        <Row form>
            <Col sd={6}>
                <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="numberOfDay" value="20" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '20' ? true:false} />{' '}
                        20 days
                </Label>
                </FormGroup>
            </Col>
            <Col sd={6}>
                <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="radio" name="numberOfDay" value="24" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '24' ? true:false} />{' '}
                        24 days
                </Label>
                </FormGroup>
            </Col>
        </Row>

    </div>

    if(courseSelected === 'fast-metabolism'){
        dayDisplay = <div>
            <Row form>
                <Col sd={6}>
                    <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                        <Label check className='orderLabel'>
                            <Input className="inputRadio" type="radio" name="numberOfDay" value="7" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '5' ? true:false} />{' '}
                            7 days
                    </Label>
                    </FormGroup>
                </Col>
                <Col sd={6}>
                    <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                        <Label check className='orderLabel'>
                            <Input className="inputRadio" type="radio" name="numberOfDay" value="14" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '6' ? true:false} />{' '}
                            14 days
                    </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sd={6}>
                    <FormGroup style={{ textAlign: 'left', marginLeft: '40px' }}>
                        <Label check className='orderLabel'>
                            <Input className="inputRadio" type="radio" name="numberOfDay" value="28" onChange={() => changeNumberOfDay()} defaultChecked={daySelectedValue === '20' ? true:false} />{' '}
                            28 days
                    </Label>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    }

    return (
        <div>
            <h3 className="text-success">Choose your plan</h3>
            <h5 className="" style={{color: '#FFB540'}}>Calories suggested {customer_recommended} KCAL</h5>
            <div className="form">
                {planView}
            </div>

            <h5 className="text-muted">Number of meal per day</h5>
            <div>
                <Row form>
                    <Col sd={6}>
                        <FormGroup>
                            <Label check className='orderLabel'>
                                <Input className="inputRadio" type="radio" name="numberOfMeal" value="2" defaultChecked={mealSelectedValue === '2' ? true:false} onChange={() => changeNumberOfMeal()} />{' '}
                                2 meal
                            <p className="NoteTextMeal">Calories will be reduce up to less meal</p>
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sd={6}>
                        <FormGroup>
                            <Label check className='orderLabel'>
                                <Input className="inputRadio" type="radio" name="numberOfMeal" value="3" defaultChecked={mealSelectedValue === '3' ||  typeof mealSelectedValue === 'undefined' ? true:false} onChange={() => changeNumberOfMeal()}  />{' '}
                                3 meal
                        </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup check style={{ textAlign: 'left', marginLeft: '80px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="checkbox" name="mealType" value="B" onChange={() => changeMealType('B')} defaultChecked />{' '}
                        Breakfast
                </Label>
                </FormGroup>
                <FormGroup check style={{ textAlign: 'left', marginLeft: '80px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="checkbox" name="mealType" value="L" onChange={() => changeMealType('L')} defaultChecked />{' '}
                        Lunch
                </Label>
                </FormGroup>
                <FormGroup check style={{ textAlign: 'left', marginLeft: '80px' }}>
                    <Label check className='orderLabel'>
                        <Input className="inputRadio" type="checkbox" name="mealType" value="D" onChange={() => changeMealType('D')} defaultChecked={mealSelectedValue === '3' ||  typeof mealSelectedValue === 'undefined' ? true:false} />{' '}
                        Dinner
                </Label>
                </FormGroup>
            </div>
            <div style={{ marginTop: '10px' }}></div>
            <h5 className="text-muted">Number of day</h5>
            {dayDisplay}
            <Row>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('course_select')}>Back</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => onChangeState()}>Next</Button>
                </Col>
            </Row>
        </div>
    )
};

const OrderCalendar = ({ nextStep, courseData, date_delivery, setDateOfDelivery, dateOfDelivery }) => {
    //console.log(courseData);
    //console.log(dateOfDelivery);
    var courses_duration = parseInt(courseData.courses_duration);

    var new_delivery = dateOfDelivery;
    //console.log('DateOfDelivery: '+new_delivery);
    const customDate = function(date){
        //console.log('New date is: '+ date)
        let date_now = new Date();
        date_now.setDate(date_now.getDate() + 1);
        let cal_date = new Date(date);
        cal_date.setDate(cal_date.getDate());
        let getYear = cal_date.getFullYear();
        let getMonth = (cal_date.getMonth() + 1) < 10 ? '0' + (cal_date.getMonth() + 1) : (cal_date.getMonth() + 1);
        let getDay = cal_date.getDate() < 10 ? '0' + cal_date.getDate() : cal_date.getDate();

        //new_delivery.push(getYear + '-' + getMonth + '-' + getDay);
        let dateFormat = getYear + '-' + getMonth + '-' + getDay;
        let index = new_delivery.indexOf(dateFormat);
        //console.log('New date is: '+ dateFormat);
        if (index > -1) {
            //console.log('Remove date is: '+ dateFormat);
            new_delivery.splice(index, 1);
        }else{
            //console.log('Add date is: '+ dateFormat);
            if(new_delivery.length < courses_duration){
                if(date > date_now){
                    if(skipEvent.indexOf(String(dateFormat)) === -1){
                        new_delivery.push(dateFormat);
                    }
                }
            }
        }

        setDateOfDelivery(new_delivery);

        //console.log(new_delivery);
    }

    const onChangeState = function () {
        if(new_delivery.length < courses_duration){
            Swal.fire('Oops...', 'Please check your shipping date', 'error')
        }else{
            nextStep('time_select');
            setDateOfDelivery(new_delivery);
        }
    }

    return (
        <div>
            <h5 className="text-muted">Select date you want to the service</h5>
            <Calendar
                calendarType="US"
                locale="en-US"
                tileClassName={({ date, view }) => {
                    let getYear = date.getFullYear();
                    let getMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                    let getDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            
                    let dateFormat = getYear + '-' + getMonth + '-' + getDay;
                    if (new_delivery.indexOf(dateFormat) >= 0) {
                        return 'eventDelivery';
                    }
                }}
                tileDisabled={({ activeStartDate, date, view }) => date.getDay() === 0}
                onClickDay={(date) => customDate(date)}
                onActiveStartDateChange={({ activeStartDate, view }) => alert('Changed view to: ', activeStartDate, view)}
            />
            <hr />
            <Row>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('plan_select')}>Back</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => onChangeState()}>Next</Button>
                </Col>
            </Row>
            <p className="NoteText" style={{ marginTop: '20px' }}>Note</p>

            <p className="NoteText text-muted">1. Our delivery service is closed every Sunday, if you want to receive Sunday's, you can contact our staff after order.
            <br />การจัดส่งจะหยุดทุกวันอาทิตย์ หากคุณลูกค้าต้องการรับมื้ออาหารของวันอาทิตย์ด้วย สามารถแจ้งกับพนักงานหลังจากการสั่งซื้อได้</p>

            <p className="NoteText text-muted">2. You can change date of delivery later by do it in advance before 2 days of delivering date
            <br />คุณสามารถเปลี่ยนวันวัดจัดส่งได้ภายหลัง โดยเปลี่ยนล่วงหน้าก่อน 2 วัน ของที่วันที่ส่งอาหาร</p>
            
        </div>
    )
};

const TimeSelect = ({ nextStep, userAddress, setCustomerShipping, setNewCustomerAddress, setShippingTime, addAddress }) => {
    const getAddress = document.getElementsByName('customer_shipping_id');


    const AddressDetail = [];
    for (let i = 0; i < userAddress.length; i++) {
        AddressDetail.push(userAddress[i]);
    }

    let addressId = '';
    const setAddress = function (getAddressId) {
        addressId = getAddressId;
        //console.log(addressId);
    }

    for (let i = 0, length = getAddress.length; i < length; i++) {
        if (getAddress[i].checked) {
            addressId = getAddress[i].value;
            break;
        }
    }

    /*let shippingTime = '';
    for (let i = 0, length = getShippingTime.length; i < length; i++) {
        if (getShippingTime[i].selected) {
            shippingTime = getShippingTime[i].value;
            console.log(shippingTime);
            break;
        }
    }*/

    const onChangeState = function () {
        var e = document.getElementById("shippingTime");
        var getShippingTime = e.options[e.selectedIndex].value;
        //setDateOfDelivery(new_delivery);
        /*var newAddress = document.getElementById('newAddress');
        
        

        if (newAddress === null) {
            setCustomerShipping(addressId);
        } else {
            setNewCustomerAddress(newAddress.value);
        }*/
        //console.log('add is',addressId);

        if(addressId === ''){
            Swal.fire('Oops...', 'Please select shipping address or add new address', 'error')
        }else{
            setCustomerShipping(addressId);
            setShippingTime(getShippingTime);
            nextStep('address_confirm');
        }

        
        //console.log(newAddress);
    }

    const addressData = AddressDetail.map((item, key) => {
        if(typeof item.customer_shipping_id === 'undefined'){
            return <div key='1'></div>
        }else{
            return <div key={item.customer_shipping_id}>
                <FormGroup className="text-muted addressList">
                    <Label check>
                        <Input
                            type="radio"
                            name="customer_shipping_id"
                            value={item.customer_shipping_id}
                            key={item.customer_shipping_id}
                            onClick={() => setAddress(item.customer_shipping_id)}
                        />
                        {item.customer_shipping_address}
                    </Label>
                </FormGroup>
            </div>
        }
        
    });

    const SetNewAddress = () => {
        if (userAddress.length < 2) {
            return (
                <div>
                    
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => addAddress()}>Add Address</Button>
                </div>
            )
        }
    }


    return (
        <div>
            <h5 className="text-muted">Select time of delivery</h5>
            <Row form>
                <Col>
                    <FormGroup>
                        <Input type="select" name="shippingTime" id="shippingTime" >
                            <DeliveryTime />
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <p className="NoteText text-muted">You can change delivery time of each day later.<br />
                สามารถเปลี่ยนเวลาจัดส่งแต่ละวันได้ภายหลัง</p>

            <h5 className="text-muted">Please choose your address</h5>
            {addressData}

            {SetNewAddress()}

            <br />
            <Row>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('date_select')}>Back</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => onChangeState()}>Next</Button>
                </Col>
            </Row>
        </div>
    )
};

const ConfirmAddress = ({ customerAddress, cutomerPhone, shippingTime, shippingStartDate, nextStep }) => (
    <div className="confirm_address">
        <h3>PLAESE CONFIRM YOUR INFORMATION</h3>
        <h5>Address:</h5>
        <p>{customerAddress}</p>

        <h5>Time of delivery:</h5>
        <p>{shippingTime}</p>

        <h5>Tel:</h5>
        <p>{cutomerPhone}</p>

        <h5>Start course on:</h5>
        <p>{shippingStartDate}</p>

        <Row>
            <Col>
                <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('time_select')}>Back</Button>
            </Col>
            <Col>
                <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('payment_select')}>Next</Button>
            </Col>
        </Row>
    </div>
);

const PaymentMethod = ({ nextStep, payMethod, totalPayment }) => {

    let paymentMethod = '';
    let newTotalFormat = parseFloat(totalPayment).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    return <div className="payment_method">
        <Form>
            <h3 className="text-muted">Total Payment</h3>
            <h5 className="text-muted">{newTotalFormat} THB</h5>
            <hr />
            <h3 className="text-muted">Payment Method</h3>
            <Row>
                <Col>
                    <FormGroup check className="NoteText text-muted">
                        <Label check className='orderLabel'>
                            <Input type="radio" name="paymentMethod" id="paymentMethod1" style={{ marginTop: '15px' }} value="bank" onClick={
                                () => {
                                    document.getElementById('bankPayment').style.display = 'block';
                                    document.getElementById('cardPayment').style.display = 'none';
                                    paymentMethod = 'bank_select';
                                }
                            } />
                            Bank Transfer
                    </Label>
                    </FormGroup>
                    <FormGroup check className="NoteText text-muted">
                        <Label check  className='orderLabel'>
                            <Input type="radio" name="paymentMethod" id="paymentMethod2" style={{ marginTop: '15px' }} value="card" onClick={
                                () => {
                                    payMethod();
                                    document.getElementById('bankPayment').style.display = 'none';
                                    document.getElementById('cardPayment').style.display = 'block';
                                    paymentMethod = 'card_select';
                                }
                            } />
                            Debit / Credit
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <div id="cardPayment" style={{ display: 'none' }}>
                <p className="NoteText text-muted">
                For card payment, refunding after  3 days of payment, refund amount will be deducted for 3.65% due to the payment system fee. <br />
                สำหรับลูกค้าที่ชำระเงินผ่านบัตร การขอคืนเงินหลัง 3 วันของวันที่ตัดบัตร ยอดคืนเงินจะถูกหักเนื่องจากค่าธรรมเนียมของระบบชำระเงิน 3.65%
                </p>
            </div>

            <div id="bankPayment" style={{ display: 'none' }}>
                <p className="NoteText text-muted">
                    Kasikorn Bank<br />
                    796-2-18858-8<br />
                    Account Name: D DOUBLE K CO., LTD.
                </p>
            </div>

            <Row>
                <Col>
                    <Button color="success" size="lg" block className="btnStyle" onClick={() => nextStep('address_confirm')}>Back</Button>
                </Col>
                <Col>
                    <Button color="success" size="lg" id="checkout-button" block className="btnStyle"
                        onClick={
                            () => {
                                if (paymentMethod !== '') {
                                    if (paymentMethod === 'card_select') {
                                        payMethod();
                                    } else {
                                        nextStep(paymentMethod)
                                    }

                                }
                            }
                        }
                    >Confirm</Button>
                </Col>
            </Row>
        </Form>
    </div>
};

const ConfirmPaymentCard = ({ closeApp }) => (
    <div className="paymentConfirm">
        <h3>Thank you,</h3>
        <h3>Your payment is complete</h3>
        <br />

        <Row>
            <Col>
                <Button color="success" size="lg" block className="btnStyle" onClick={() => closeApp()}>Close</Button>
            </Col>
        </Row>
    </div>
);

const ConfirmPaymentBank = ({ closeApp }) => (
    <div className="paymentConfirm">
        <h3>Important!</h3>

        <p>
            After you complete payment please send trafer slip to LINE to confirm
        </p>
        <p>
            หลังจากชำระเงินเรียบร้อยแล้ว รบกวนคุณลูกค้าส่งสลิปหลักฐานการโอนเงินไปใน LINE เพื่อคอนเฟิร์มการสั่งซื้อค่ะ
        </p>
        <Row>
            <Col>
                <Button color="success" size="lg" block className="btnStyle" onClick={() => closeApp()}>Close</Button>
            </Col>
        </Row>
    </div>
);

const DeliveryTime = () => {
    const TimeOfDelivery = [
        <option key='1' value="05:00-05:30">05:00-05:30</option>,
        <option key='2' value="05:30-06:00">05:30-06:00</option>,
        <option key='3' value="06:00-06:30">06:00-06:30</option>,
        <option key='4' value="06:30-07:00">06:30-07:00</option>,
        <option key='5' value="07:00-07:30">07:00-07:30</option>,
        <option key='6' value="07:30-08:00">07:30-08:00</option>,
        <option key='7' value="08:00-08:30">08:00-08:30</option>,
        <option key='8' value="08:30-09:00">08:30-09:00</option>,
    ];
    return TimeOfDelivery;
};

import React from 'react';
import { Progress, Media, Col, Row, FormGroup, Input, Button } from 'reactstrap';
import './tdee.css';
import Header from '../Header/index'
import Loading from '../Loading/index'

import CalLevel from '../../assets/cal_level.png';
import Arrow from '../../assets/arrow.png';
import axios from "axios";

const liff = window.liff;
const activity_array = ['No exercise or exercise less than 2 days per week', 'Walking', 'Yoga', 'Fitness', 'Running', 'Swimming', 'Boxing', 'Weight training', 'Cardio', 'Run marathon'];
const activity_list = activity_array.map((actList, i) =>
    <option key={i} value={actList}>{actList}</option>
);

const goal_array = ['Lose weight', 'Maintain weight', 'Gain weight'];
const goal_list = goal_array.map((goalList, i) =>
    <option key={i} value={goalList}>{goalList}</option>
);

export default class Tdee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            flagDisplay: false,
            displayName: '',
            userId: process.env.REACT_APP_DEFAULT_LINE_ID,
            pictureUrl: '',
            statusMessage: '',
            userData: '',
            yearSelect: '',
            monthSelect: '',
            daySelect: '',
            step: 1,
            customer_height: '',
            customer_weight: '',
            customer_activity: 'No exercise or exercise less than 2 days per week',
            customer_goal: 'Maintain weight',
            customer_sex: 'female',
            customer_bmr: '',
            customer_tdee: '',
            customer_recommended: '',
            error_weight: false,
            error_height: false
        }

        this.changeStep = this.changeStep.bind(this);
        this.initialize = this.initialize.bind(this);
        this.closeApp = this.closeApp.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //window.addEventListener('load', this.initialize);
        this.initialize();

        if (process.env.NODE_ENV !== 'production') {
            //for test
            this.getUsers();
        }
    }

    async initialize() {
        // liff.init(async (data) => {
        //     let profile = await liff.getProfile();
        //     this.setState({
        //         displayName: profile.displayName,
        //         userId: profile.userId,
        //         pictureUrl: profile.pictureUrl,
        //         statusMessage: profile.statusMessage,
        //         flagDisplay: true
        //     });

        //     this.getUsers();
        // });
        const liffid = '1607959730-83RmXDNn';
        await liff.init({ liffId: `${liffid}` }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            let profile = await liff.getProfile();
            // this.setState({
            //     name: getProfile.displayName,
            //     userLineID: getProfile.userId,
            //     pictureUrl: getProfile.pictureUrl,
            // });
            this.setState({
                displayName: profile.displayName,
                userId: profile.userId,
                pictureUrl: profile.pictureUrl,
                statusMessage: profile.statusMessage,
                //flagDisplay: true
            });
            //alert(profile.userId);
            this.getUsers();
        }else{
            liff.login();
        }
    }

    async getUsers() {
        const line_user_id = this.state.userId
        const api = process.env.REACT_APP_API_URL + 'LineGetUsers/' + line_user_id;
        //alert(api);
        await axios.get(api, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'text/json',
                'Accept': '*/*',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then(res => {
                //console.log(res.data);
                let userData = res.data;

                if (userData[0].status === 'fail') {
                    this.setState({
                        userType: 'new'
                    });
                } else {
                    //var dateSelect = userData[0].customer_dob.split('-');
                    //const yearSelect = dateSelect[0];
                    //const monthSelect = dateSelect[1];
                    //const daySelect = dateSelect[2];

                    let customer_goal = userData[0].customer_goal;

                    if(customer_goal === '' || customer_goal === 'Be healthy'){
                        customer_goal = 'Maintain weight'
                    }

                    this.setState({
                        userType: 'current',
                        userData: userData[0],
                        /*yearSelect: yearSelect,
                        monthSelect: monthSelect,
                        daySelect: daySelect,
                        customer_fullname: userData[0].customer_fullname,
                        customer_phone: userData[0].customer_phone,
                        customer_nickname: userData[0].customer_nickname,
                        customer_height: userData[0].customer_height,
                        customer_weight: userData[0].customer_weight,
                        customer_activity: userData[0].customer_activity,
                        customer_goal: customer_goal,
                        customer_sex: userData[0].customer_sex,*/
                        flagDisplay: true
                    });

                    //console.log(yearSelect);
                }

                this.setState({
                    flagDisplay: true
                });

            });

    }

    handleChange(event) {
        //this.setState({value: event.target.value});
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'yob') {
            this.setState({ yearSelect: event.target.value });
        }

        if (event.target.name === 'customer_weight') {
            this.setState({ error_weight: false });
        }

        if (event.target.name === 'customer_height') {
            this.setState({ error_height: false });
        }

        if (event.target.name === 'yob') {
            this.setState({ yearSelect: event.target.value });
        }

        console.log(event.target.value);
    }

    closeApp(event) {
        liff.closeWindow();

        liff.openWindow({
            url:'line://app/1607959730-JxgDABMX',
            external:false
        });

        liff.sendMessages([
            {
                "type": "text",
                "text": `Calories suggested ${this.state.customer_recommended} Kcal`
            },
            {
                "type": "flex",
                "altText": "Calories suggested",
                "contents": {
                  "type": "bubble",
                  "direction": "ltr",
                  "header": {
                    "type": "box",
                    "layout": "vertical",
                    "contents": [
                      {
                        "type": "text",
                        "text": "Calories suggested",
                        "align": "center",
                        "weight": "bold"
                      }
                    ]
                  },
                  "body": {
                    "type": "box",
                    "layout": "vertical",
                    "contents": [
                      {
                        "type": "text",
                        "text": `Recommendation for ${this.state.customer_goal}`,
                        "align": "center",
                        "wrap": true
                      },
                      {
                        "type": "text",
                        "text": `${this.state.customer_recommended}`,
                        "size": "4xl",
                        "align": "center",
                        "gravity": "center",
                        "weight": "bold"
                      },
                      {
                        "type": "text",
                        "text": "KCAL",
                        "align": "center"
                      }
                    ]
                  },
                  "footer": {
                    "type": "box",
                    "layout": "horizontal",
                    "contents": [
                      {
                        "type": "button",
                        "action": {
                          "type": "uri",
                          "label": "Find Course",
                          "uri": "line://app/1607959730-JxgDABMX"
                        },
                        "style": "primary"
                      }
                    ]
                  }
                }
              }
        ])
            .then(() => {
                console.log('message sent');
            })
            .catch((err) => {
                console.log('error', err);
            });
        
    }

    changeStep(step) {

        let bmr = '';
        let tdee = '';
        let recommended = '';
        let customer_sex = this.state.customer_sex;
        let customer_height = this.state.customer_height;
        let customer_weight = this.state.customer_weight;
        let customer_activity = this.state.customer_activity;
        let customer_goal = this.state.customer_goal;
        let custmer_yob = this.state.yearSelect;
        let currentYear = new Date().getFullYear();
        let customer_age = currentYear - custmer_yob;
        let nextStep = true;

        if(customer_weight === '' || customer_weight === 0 || customer_weight === '0'){
            nextStep = false;
            this.setState({ error_weight: true });
        }

        if(customer_height === '' || customer_height === 0 || customer_height === '0'){
            nextStep = false;
            this.setState({ error_height: true });
        }

        if(!nextStep){
            return;
        }

        if (customer_sex === 'male') {
            bmr = (10 * parseInt(customer_weight)) + (6.25 * parseInt(customer_height)) - (5 * parseInt(customer_age)) + 5;
        } else {
            bmr = (10 * parseInt(customer_weight)) + (6.25 * parseInt(customer_height)) - (5 * parseInt(customer_age)) - 161;
        }
        //'No exercise', 'Walking', 'Yoga', 'Fitness', 'Running', 'Swimming', 'Boxing', 'Weight training', 'Cardio', 'Run mararhon'
        switch (customer_activity) {
            default: {
                tdee = bmr * 1.2;
                break;
            }
            case 'Walking': {
                tdee = bmr * 1.25;
                break;
            }
            case 'Yoga': {
                tdee = bmr * 1.3;
                break;
            }
            case 'Fitness': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Running': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Swimming': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Boxing': {
                tdee = bmr * 1.55;
                break;
            }
            case 'Weight training': {
                tdee = bmr * 1.55;
                break;
            }
            case 'Cardio': {
                tdee = bmr * 1.375;
                break;
            }
            case 'Run mararhon': {
                tdee = bmr * 1.55;
                break;
            }
        }
        //alert(tdee);
        //'Lose weight', 'Be healthy', 'Gain weight'
        let recommended_percent = 100;
        switch (customer_goal) {
            default: {
                //recommended = (bmr + tdee) / 2;
                recommended = tdee - 500;
                recommended_percent = 0;
                break;
            }
            case 'Maintain weight': {
                recommended = tdee;
                recommended_percent = 45;
                break;
            }
            case 'Gain weight': {
                recommended = tdee + 300;
                recommended_percent = 100;
                break;
            }
        }

        //cal bar
        //let min = 800;
        //let max = recommended;
        /*let recommended_percent = 100;
        let tdee_percent = Math.round((tdee * 100) / recommended) - 30;
        if (tdee > recommended) {
            //max = tdee;
            if(customer_goal === 'Lose weight'){
                recommended_percent = 35;
            }else{
                recommended_percent = Math.round((recommended * 100) / tdee);
            }
            
            tdee_percent = 100;
        }*/
        let tdee_percent = 100;

        this.setState({
            customer_bmr: Math.round(bmr),
            customer_tdee: Math.round(tdee),
            customer_recommended: Math.round(recommended),
            tdee_percent: tdee_percent,
            recommended_percent: recommended_percent,
            step: step
        })

        //alert(recommended);

        /*this.setState({
            step: step
        });*/

        //alert(step);
    }

    DayofBirth() {
        const day = [];
        for (var i = 1; i <= 31; i++) {
            let dayFormat = i;
            if (dayFormat < 10) {
                dayFormat = '0' + dayFormat;
            }

            day.push(<option key={i} value={dayFormat}>{dayFormat}</option>);

        }
        return day;
    }

    MonthofBirth() {
        const month = [];
        for (var i = 1; i <= 12; i++) {
            let monthFormat = i;
            if (monthFormat < 10) {
                monthFormat = '0' + monthFormat;
            }
            
            month.push(<option key={i} value={monthFormat}>{monthFormat}</option>);
        }
        return month;
    }

    YearofBirth() {
        let currentYear = new Date().getFullYear();
        let startYear = currentYear - 80
        const year = [];
        for (var i = startYear; i <= currentYear; i++) {
            year.push(<option key={i} value={i}>{i}</option>);
        }
        return year;
    }

    render() {

        let displayStep;

        switch (this.state.step) {
            case 1: {
                displayStep = <CustomerProfile
                    nextStep={() => this.changeStep(2)}
                    DayofBirth={this.DayofBirth()}
                    MonthofBirth={this.MonthofBirth()}
                    YearofBirth={this.YearofBirth()} 
                    customer_goal={this.state.customer_goal}
                    UserData=''
                    handleChange={this.handleChange} 
                    errorWeight={this.state.error_weight} 
                    errorHeight={this.state.error_height}
                />;
                break;
            }
            case 2: {
                displayStep = <CalTdee
                    nextStep={() => this.changeStep(3)}
                    closeApp={() => this.closeApp()}
                    customer_goal={this.state.customer_goal}
                    customer_bmr={this.state.customer_bmr}
                    customer_tdee={this.state.customer_tdee}
                    customer_recommended={this.state.customer_recommended}
                    tdee_percent={this.state.tdee_percent}
                    recommended_percent={this.state.recommended_percent}
                />;
                break;
            }
            default: {
                displayStep = <CustomerProfile
                    nextStep={() => this.changeStep(2)}
                    DayofBirth={this.DayofBirth()}
                    MonthofBirth={this.MonthofBirth()}
                    YearofBirth={this.YearofBirth()}
                    UserData={this.state.userData}
                    handleChange={this.handleChange}
                />;
                break;
            }
        }

        if (this.state.flagDisplay === true) {
            return (
                <div className="home">
                    <Header />

                    <div className="tdee">
                        {displayStep}
                    </div>
                </div>
            );

        } else {
            return (
                <div className="home">
                    <Header />

                    <div className="tdee">
                        <Loading />
                    </div>
                </div>
            );
        }


    }
}

const CustomerProfile = ({ nextStep, DayofBirth, MonthofBirth, YearofBirth, UserData, closeApp, handleChange, errorWeight, errorHeight, customer_goal }) => {
    //const fields = this.state.fields;
    //console.log(UserData.customer_dob);
    var getBirthDay = new Date();
    if(typeof UserData.customer_dob !== 'undefined'){
        getBirthDay = new Date(UserData.customer_dob);
    }

    const BirthDay = getBirthDay.getDate() < 10 ? '0'+getBirthDay.getDate():getBirthDay.getDate();
    const BirthMonth = (getBirthDay.getMonth()+1) < 10 ? '0'+(getBirthDay.getMonth()+1):(getBirthDay.getMonth()+1);
    const BirthYear = getBirthDay.getFullYear();
    

    return (
    <div>
        <h2 className="text-success">TDEE Test</h2>
        <hr />

        <h5 className="text-muted">Birthday</h5>
        <Row form>
            <Col sd={4}>
                <FormGroup>
                    <Input type="select" name="dob" id="dob" onChange={handleChange} defaultValue={BirthDay}>
                        {DayofBirth}
                    </Input>
                </FormGroup>
            </Col>
            <Col sd={4}>
                <FormGroup>
                    <Input type="select" name="mob" id="mob" onChange={handleChange} defaultValue={BirthMonth}>
                        {MonthofBirth}
                    </Input>
                </FormGroup>
            </Col>
            <Col sd={4}>
                <FormGroup>
                    <Input type="select" name="yob" id="yob" onChange={handleChange} defaultValue={BirthYear}>
                        {YearofBirth}
                    </Input>
                </FormGroup>
            </Col>
        </Row>

        <FormGroup>
            <Input type="select" name="customer_sex" id="customer_sex" defaultValue={UserData.customer_sex} onChange={handleChange}>
                <option value='female'>Female</option>
                <option value='male'>Male</option>
            </Input>
        </FormGroup>

        <FormGroup>
            <Input invalid={errorWeight} type="number" name="customer_weight" id="customer_weight" placeholder="Weight" defaultValue={UserData.customer_weight === '0' ? '' : UserData.customer_weight} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
            <Input invalid={errorHeight} type="number" name="customer_height" id="customer_height" placeholder="Height" defaultValue={UserData.customer_height === '0' ? '' : UserData.customer_height} onChange={handleChange} />
        </FormGroup>
        <FormGroup>
            <Input type="select" name="customer_activity" id="customer_activity" defaultValue={UserData.customer_activity} onChange={handleChange}>
                {activity_list}
            </Input>
        </FormGroup>
        <FormGroup>
            <Input type="select" name="customer_goal" id="customer_goal" defaultValue={customer_goal} onChange={handleChange}>
                {goal_list}
            </Input>
        </FormGroup>

        <Button color="success" size="lg" className="btnStyle" block onClick={nextStep}>Next</Button>
    </div>
)}

/*const CalTdee = ({ nextStep, closeApp, customer_goal, customer_bmr, customer_tdee, customer_recommended, tdee_percent, recommended_percent }) => (
    <div>
        <h4 className="text-success">Recommendation for {customer_goal}</h4>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark', textAlign: 'center' }}>
            <Progress bar value={recommended_percent} color='white'></Progress>
            <Progress bar value="35" color='white'>
                <div style={{ color: '#000' }}>Calories suggested<br />{customer_recommended} kcal</div>
                <center><Media middle object data-src={Arrow} src={Arrow} alt="Cal" style={{ width: '30%' }} /></center>
            </Progress>
        </Progress>
        <Media>
            <Media middle href="#">
                <Media object data-src={CalLevel} src={CalLevel} alt="Generic placeholder image" />
            </Media>
        </Media>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark' }}>
            <Progress bar color='white' value="10" style={{ color: 'dark !important', backgroundColor: '#fff' }}>
                <div style={{ color: '#000' }}>BMR<br />{customer_bmr}<br />kcal</div>
            </Progress>
            <Progress bar value={tdee_percent} color='white'></Progress>
            <Progress bar color="white" value="10">
                <div style={{ color: '#000' }}>
                    TDEE<br />{customer_tdee}<br />kcal
                </div>
            </Progress>
        </Progress>
        <div className='textReccomended'>
            <p className='text-muted'>
                You should not consume less than your BMR as it will reduce your metabolism effectiveness
            </p>
            <p className='text-muted'>
                คุณไม่ควรรับแคลลอรี่ต่ำกว่าค่า BMR ของคุณเนื่องจากจะทำให้ประสิทธิภาพของระบบเผาพลาญน้อยลง
            </p>
        </div>
        <Button color="success" size="lg" className="btnStyle" block onClick={closeApp}>Find my course</Button>
    </div>
)*/

const CalTdee = ({ nextStep, closeApp, customer_goal, customer_bmr, customer_tdee, customer_recommended, tdee_percent, recommended_percent }) => (
    <div>
        <h4 className="text-success">Recommendation for <br/>{customer_goal}</h4>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark', textAlign: 'center' }}>
            <Progress bar value={recommended_percent} color='white'></Progress>
            <Progress bar value="10" color='white'>
                <center><Media middle object data-src={Arrow} src={Arrow} alt="Cal" style={{ width: '100%' }} /></center>
            </Progress>
        </Progress>
        <Media>
            <Media middle href="#">
                <Media object data-src={CalLevel} src={CalLevel} alt="Generic placeholder image" />
            </Media>
        </Media>
        <Progress multi style={{ height: '60px', backgroundColor: '#fff', color: 'dark' }}>
            <Progress bar color='white' value="12" style={{ color: 'dark !important', backgroundColor: '#fff' }}>
                <div style={{ color: '#000' }}>Lose<br />weight</div>
            </Progress>
            <Progress bar value={tdee_percent} color='white'>
                <div style={{ color: '#000' }}>Maintain<br />weight</div>
            </Progress>
            <Progress bar color="white" value="12">
                <div style={{ color: '#000' }}>
                    Gain<br />weight
                </div>
            </Progress>
        </Progress>
        <div>
            <h3 className="CourseSelection">Calories suggested</h3>
            <h3 className="CourseSelection">{customer_recommended}kcal/day</h3>
        </div>
        
        <Button color="success" size="lg" block className="btnStyle" onClick={() => closeApp()}>Next</Button>
    </div>
)
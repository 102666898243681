import React from 'react';
import { Redirect } from 'react-router-dom'
//import queryString from 'query-string';
import { Row, Col, ListGroup, ListGroupItem, Collapse, Button } from 'reactstrap';
import './faq.css';
import Header from '../Header/index'

const liff = window.liff;

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultLanguage: 'en',
            cssEnActive: 'activeLang',
            cssThActive: '',
            collapseQ1: false,
            collapseQ2: false,
            collapseQ3: false,
            collapseQ4: false,
            collapseQ5: false,
            collapseQ6: false,
            flagRedirect: false,
            flagBlog: false
        }

        this.handleChangeLang = this.handleChangeLang.bind(this);
        this.toggleQ1 = this.toggleQ1.bind(this);
        this.toggleQ2 = this.toggleQ2.bind(this);
        this.toggleQ3 = this.toggleQ3.bind(this);
        this.toggleQ4 = this.toggleQ4.bind(this);
        this.toggleQ5 = this.toggleQ5.bind(this);
        this.toggleQ6 = this.toggleQ6.bind(this);
        this.gotosignup = this.gotosignup.bind(this);
        this.gotoblog = this.gotoblog.bind(this);
    }

    componentDidMount() {
        //alert(this.state.defaultLanguage);
        if (this.state.defaultLanguage === 'en') {
            this.setState({
                cssEnActive: 'activeLang',
                cssThActive: ''
            });
        } else {
            this.setState({
                cssEnActive: '',
                cssThActive: 'activeLang'
            });
        }
    }

    gotosignup(){
        this.setState({
            flagRedirect: true
        });
    }

    gotoblog() {
        liff.closeWindow();
        liff.openWindow({
            url: 'https://www.healthytastyclubbyht.com/blog',
            external: false
        });
    }

    handleChangeLang(lang) {
        if (lang === 'en') {
            this.setState({
                defaultLanguage: 'en',
                cssEnActive: 'activeLang',
                cssThActive: '',
                collapseQ1: false,
                collapseQ2: false,
                collapseQ3: false,
                collapseQ4: false,
                collapseQ5: false,
                collapseQ6: false,
            });
        } else {
            this.setState({
                defaultLanguage: 'th',
                cssEnActive: '',
                cssThActive: 'activeLang',
                collapseQ1: false,
                collapseQ2: false,
                collapseQ3: false,
                collapseQ4: false,
                collapseQ5: false,
                collapseQ6: false,
            });
        }
    }

    toggleQ1() {
        //alert(toggleName);
        this.setState(state => ({ collapseQ1: !state.collapseQ1 }));
    }

    toggleQ2() {
        //alert(toggleName);
        this.setState(state => ({ collapseQ2: !state.collapseQ2 }));
    }

    toggleQ3() {
        //alert(toggleName);
        this.setState(state => ({ collapseQ3: !state.collapseQ3 }));
    }

    toggleQ4() {
        //alert(toggleName);
        this.setState(state => ({ collapseQ4: !state.collapseQ4 }));
    }

    toggleQ5() {
        //alert(toggleName);
        this.setState(state => ({ collapseQ5: !state.collapseQ5 }));
    }

    toggleQ6() {
        //alert(toggleName);
        this.setState(state => ({ collapseQ6: !state.collapseQ6 }));
    }

    render() {

        //let url = this.props.location.search;
        //let params = queryString.parse(url);
        //console.log(params);
        if(this.state.flagRedirect === true){
            return <Redirect to='/' />
        }

        if (this.state.defaultLanguage === 'en') {
            return (
                <div className="home">
                    <Header />

                    <div className="faq">
                        <h3>FAQ</h3>
                        <ListGroup>
                            <ListGroupItem onClick={this.toggleQ1}>Delivery area?</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ1}>
                            Deliver all Bangkok and some of metropolitan area, you can ask our staff for delivery area available and options.
                        </Collapse>
                            <ListGroupItem onClick={this.toggleQ2}>Time of delivery?</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ2}>
                                We will deliver day by day from 5.00 am to 9.00 am
                                which you can specify time of delivery
                        </Collapse>
                            <ListGroupItem onClick={this.toggleQ3}>How to update my profile?</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ3}>
                                <Button color="success" size="lg" block className="btnStyle" onClick={() => {this.gotosignup()}}>Update Profile</Button>
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ4}>Can I change date, address and time of delivery.</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ4}>
                                You can click to the Agenda in LINE menu and select the
                                date you want to change. However, any change has been
                                made in advance 2 days before the date of delivery.
                        </Collapse>
                            <ListGroupItem onClick={this.toggleQ5}>Where can I see the menu?</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ5}>
                                Our chef will prepare different menu for each week.
                            Choose the course you want to see menu.<br />
                                <p style={{ textAlign: 'left', paddingLeft: '90px' }}>
                                <a href="https://www.healthytastyclubbyht.com/healthy-diet-menu" style={{color: '#AED864'}}>1. Healthy Diet</a><br />
                                <a href="https://www.healthytastyclubbyht.com/intensive-course-menu" style={{color: '#AED864'}}>2. Intensive Fast Metabolism</a><br />
                                <a href="https://www.healthytastyclubbyht.com/paleo-diet" style={{color: '#AED864'}}>3. Perfect Paleo</a><br />
                                <a href="https://www.healthytastyclubbyht.com/keto-diet-menu" style={{color: '#AED864'}}>4. Keto Diet</a><br />
                                <a href="https://www.healthytastyclubbyht.com/mother-plan-menu" style={{color: '#AED864'}}>5. Mother Plan</a><br />
                                <a href="https://www.healthytastyclubbyht.com/recovery-menu" style={{color: '#AED864'}}>6. Recovery Plan</a><br />
                                <a href="https://www.healthytastyclubbyht.com/vegetarian-menu" style={{color: '#AED864'}}>7. Vegetarian Diet</a><br />
                                </p>
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ6}>Visit our blog for more detail and tips</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ6}>
                                <Button color="success" size="lg" block className="btnStyle" onClick={() => {this.gotoblog()}}>Visit Blog</Button>
                            </Collapse>
                        </ListGroup>
                    </div>

                    <div className='languageBar'>
                        <Row>
                            <Col className={'languageBarMenu ' + this.state.cssEnActive} onClick={() => this.handleChangeLang('en')}>ENG</Col>
                            <Col className={'languageBarMenu ' + this.state.cssThActive} onClick={() => this.handleChangeLang('th')}>ไทย</Col>
                        </Row>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="home">
                    <Header />

                    <div className="faq">
                        <h3>คำถามที่พบบ่อย</h3>
                        <ListGroup>
                            <ListGroupItem onClick={this.toggleQ1}>เขตการจัดส่ง</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ1}>
                            ทางร้านสามารถจัดส่งได้ทั่วกรุงเทพฯ และเขตปริมณฑลบางเขต คุณลูกค้าสามารถสอบถามพื้นที่จัดส่งและวิธีการส่งเพิ่มเติมกับทางเจ้าหน้าที่ทางไลน์ได้เลยค่ะ
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ2}>เวลาการจัดส่ง</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ2}>
                                เราจะจัดส่งวันต่อวัน ตั้งแต่ช่วงตี 5 ถึง 9 โมงเช้าโดยคุณลูกค้า
                                สามารถแจ้งช่วงเวลาที่สะดวกรับได้
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ3}>การอัพเดตข้อมูลส่วนตัว (Profile)</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ3}>
                                <Button color="success" size="lg" block className="btnStyle" onClick={() => {this.gotosignup()}}>Update Profile</Button>
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ4}>การเปลี่ยนวัน เวลา และสถานที่จัดส่ง</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ4}>
                            คุณลูกค้าไปที่เมนู(ตรงด้านล่างของไลน์) จากนั้นคล๊กิ ที่ Agenda
                            และเลือกวันที่ต้องการเปลี่ยนแปลง โปรดปรับเปลี่ยนข้อมูลการจัด
                            ส่งล่วงหน้า 2 วันก่อนวันที่จัดส่ง
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ5}>คุณสามารถดูเมนูได้จากที่ไหน</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ5}>
                            เชฟของเราจะออกแบบเมนูแต่ละสัปดาห์ไม่ซํ้ากัน คุณลูกค้า
                            สามารถดูเมนูของสัปดาห์นี้โดยเลือกดูเมนูคอร์สที่ต้องการดังนี้<br />
                                <p style={{ textAlign: 'left', paddingLeft: '90px' }}>
                                <a href="https://www.healthytastyclubbyht.com/healthy-diet-menu" style={{color: '#AED864'}}>1. Healthy Diet</a><br />
                                <a href="https://www.healthytastyclubbyht.com/intensive-course-menu" style={{color: '#AED864'}}>2. Intensive Fast Metabolism</a><br />
                                <a href="https://www.healthytastyclubbyht.com/paleo-diet" style={{color: '#AED864'}}>3. Perfect Paleo</a><br />
                                <a href="https://www.healthytastyclubbyht.com/keto-diet-menu" style={{color: '#AED864'}}>4. Keto Diet</a><br />
                                <a href="https://www.healthytastyclubbyht.com/mother-plan-menu" style={{color: '#AED864'}}>5. Mother Plan</a><br />
                                <a href="https://www.healthytastyclubbyht.com/recovery-menu" style={{color: '#AED864'}}>6. Recovery Plan</a><br />
                                <a href="https://www.healthytastyclubbyht.com/vegetarian-menu" style={{color: '#AED864'}}>7. Vegetarian Diet</a><br />
                                </p>
                            </Collapse>
                            <ListGroupItem onClick={this.toggleQ6}>เข้าไปดูรายละเอียดและเคล็ดลับการดูแลสุขภาพได้ที่นี้</ListGroupItem>
                            <Collapse className='collapseStyle' isOpen={this.state.collapseQ6}>
                                <Button color="success" size="lg" block className="btnStyle" onClick={() => {this.gotoblog()}}>Visit Blog</Button>
                            </Collapse>
                        </ListGroup>
                    </div>

                    <div className='languageBar'>
                        <Row>
                            <Col className={'languageBarMenu ' + this.state.cssEnActive} onClick={() => this.handleChangeLang('en')}>ENG</Col>
                            <Col className={'languageBarMenu ' + this.state.cssThActive} onClick={() => this.handleChangeLang('th')}>ไทย</Col>
                        </Row>
                    </div>
                </div>
            );
        }
    }
}
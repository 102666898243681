import React, { Component } from 'react';
import queryString from 'query-string';
import './style.css';
//import { Col, Row, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from "axios";
//import Swal from 'sweetalert2'

import Header from '../Header/index'
import Loading from '../Loading/index'

//const liff = window.liff;

class OrderComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flagDisplay: false,
            dataParse: '',
            successPayment: false
        }

        this.confirmOrder = this.confirmOrder.bind(this);
    }

    componentDidMount(){
        let url = this.props.location.search;
        let params = queryString.parse(url);

        console.log(params);
        console.log(typeof(Storage));

        if(typeof params.token !== 'undefined'){
            console.log(params.token);
            this.setState({
                dataParse: params.token
            });

            this.confirmOrder(params.token);
        }
    }

    async confirmOrder(dataParse){
        let data = {
            token: dataParse,
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }

        const api = process.env.REACT_APP_API_URL + 'ConfirmOrder/';
        await axios.post(api, data, {
            headers: headers
        })
            .then(res => {
                //Swal.fire('Oops...', 'Something went wrong!', 'error')
                //console.log(res.status);
                //console.log(res.data[0]);
                const resData = res.data[0];
                if(resData.status === 'success' || resData.status === 'approve'){
                    this.setState({
                        flagDisplay: true,
                        successPayment: true
                    });
                }else{
                    this.setState({
                        flagDisplay: true,
                        successPayment: false
                    });
                }
                

                /*Swal.fire({
                    title: 'Thank you',
                    text: 'Your order is completed',
                    type: 'success',
                    onClose: () => {
                        //this.setState({ flagDisplay: true });
                        liff.closeWindow();
                    }
                });*/

                
            });
    }

    render(){

        if(this.state.flagDisplay){
            if(this.state.successPayment){
                return (
                    <div className="home">
                        <Header />
        
                        <div className="signup">
                            <div className="paymentConfirm">
                                <h3>ORDER CREATED</h3>
                                <h5>Please wait for payment confirmation</h5>
                                <h5>ทางร้านได้รับออเดอร์เรียบร้อยค่ะ โปรดรอการตรวจสอบการรับยอดชำระสักครู่ค่ะ</h5>
                                <br />
                            </div>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="home">
                        <Header />
        
                        <div className="signup">
                            <div className="paymentConfirm">
                                <h3>ORDER CREATED</h3>
                                <h5>Please wait for payment confirmation</h5>
                                <h5>ทางร้านได้รับออเดอร์เรียบร้อยค่ะ โปรดรอการตรวจสอบการรับยอดชำระสักครู่ค่ะ</h5>
                                <br />
                            </div>
                        </div>
                    </div>
                )
            }
            

            
        }else{
            return (
                <div className="home">
                    <Header />
    
                    <div className="signup">
                        <Loading />
                    </div>
                </div>
            )
        }
        
    }
}

export default OrderComplete;
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import queryString from 'query-string';
import './index.css';
import { Col, Row, Button, Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import axios from "axios";
import Swal from 'sweetalert2'

//import history from "../../history";

import Header from '../Header/index'
import Loading from '../Loading/index'

const liff = window.liff;
const activity_array = ['No exercise or exercise less than 2 days per week', 'Walking', 'Yoga', 'Fitness', 'Running', 'Swimming', 'Boxing', 'Weight training', 'Cardio', 'Run mararhon'];
const activity_list = activity_array.map((actList, i) =>
    <option key={i} value={actList}>{actList}</option>
);

const goal_array = ['Lose weight', 'Be healthy', 'Gain weight'];
const goal_list = goal_array.map((goalList, i) =>
    <option key={i} value={goalList}>{goalList}</option>
);

const default_activity = 'No exercise or exercise less than 2 days per week';
const default_goal = 'Lose weight';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            userId: process.env.REACT_APP_DEFAULT_LINE_ID,
            pictureUrl: '',
            statusMessage: '',
            flagDisplay: false,
            userType: '',
            userData: '',
            yearSelect: '',
            monthSelect: '',
            daySelect: '',
            userAddress: '',
            fields: {
                customer_activity: 'No exercise or exercise less than 2 days per week',
                customer_goal: 'Lose weight',
            },
            errors: {},
            callBackLink: '',
            flagRedirect: false
        };
        this.initialize = this.initialize.bind(this);
        this.closeApp = this.closeApp.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.handdleSignup = this.handdleSignup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.addUser = this.addUser.bind(this);

        this.getAddress = this.getAddress.bind(this);
        this.addAddress = this.addAddress.bind(this);
    }

    componentDidMount() {
        //window.addEventListener('load', this.initialize);
        this.initialize();

        let url = this.props.location.search;
        let params = queryString.parse(url);

        if(typeof params.callback !== 'undefined'){
            //console.log(params.callback);
            this.setState({
                callBackLink: params.callback
            })
        }

        if (process.env.NODE_ENV !== 'production') {
            //for test
            this.getUsers();
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["customer_fullname"]) {
            formIsValid = false;
            errors["customer_fullname"] = true;
        }

        if (!fields["customer_nickname"]) {
            formIsValid = false;
            errors["customer_nickname"] = true;
        }

        if (!fields["customer_phone"]) {
            formIsValid = false;
            errors["customer_phone"] = true;
        }

        if (fields["customer_weight"] <= 0 || !fields["customer_weight"]) {
            formIsValid = false;
            errors["customer_weight"] = true;
        }

        if (fields["customer_height"] <= 0 || !fields["customer_height"]) {
            formIsValid = false;
            errors["customer_height"] = true;
        }

        /*if (typeof fields["name"] !== "undefined") {
            if (!fields["name"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["name"] = "Only letters";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }*/

        this.setState({ errors: errors });
        return formIsValid;
    }

    handdleSignup(e) {
        //this.props.history.push('/orders');
        //liff.closeWindow();
        //console.log(this.state.fields);
        e.preventDefault();

        if (this.handleValidation()) {
            //alert("Form submitted");
            let userProfile = this.state.fields;

            //let customer_token = userProfile['customer_token'];
            let customer_line_id = this.state.userId;
            let customer_fullname = userProfile['customer_fullname'];
            let customer_nickname = userProfile['customer_nickname'];
            let customer_phone = userProfile['customer_phone'];
            let customer_weight = userProfile['customer_weight'];
            let customer_height = userProfile['customer_height'];
            let customer_activity = userProfile['customer_activity'] === '' ? default_activity:userProfile['customer_activity'];
            let customer_goal = userProfile['customer_goal'] === '' ? default_goal:userProfile['customer_goal'];
            let dob = userProfile['dob'];
            let mob = userProfile['mob'];
            let yob = userProfile['yob'];
            let customer_sex = userProfile['customer_sex'] === '' || typeof userProfile['customer_sex'] === "undefined"? 'female':userProfile['customer_sex'];;

            if (typeof dob === "undefined") {
                dob = parseInt(this.state.daySelect);
            }

            if (typeof mob === "undefined") {
                mob = parseInt(this.state.monthSelect);
            }

            if (typeof yob === "undefined") {
                yob = parseInt(this.state.yearSelect);
            }

            if (dob < 10) {
                dob = '0' + dob;
            }

            if (mob < 10) {
                mob = '0' + mob;
            }

            let customer_dob = yob + '-' + mob + '-' + dob;
            let data = {
                customer_line_id: customer_line_id,
                customer_fullname: customer_fullname,
                customer_nickname: customer_nickname,
                customer_phone: customer_phone,
                customer_weight: customer_weight,
                customer_height: customer_height,
                customer_activity: customer_activity,
                customer_goal: customer_goal,
                customer_dob: customer_dob,
                customer_sex: customer_sex,
                customer_line_name: this.state.displayName
            };

            console.log(data);

            if (this.state.userType === 'new') {
                this.addUser(data);
            } else {
                this.updateUser(data);
            }
        }
    }

    handleChange(field, e) {
        //console.log(e.target.name);
        //console.log(' - '+e.target.value);
        const regexp = /^[0-9\b]+$/;
        let fields = this.state.fields;
        let errors = this.state.errors;
        if(e.target.name === 'customer_phone'){
            //console.log(regexp.test(e.target.value));
            if (regexp.test(e.target.value)) {
                fields[field] = e.target.value;
                errors[field] = false;
                this.setState({ fields });
            }else{
                fields[field] = e.target.value;
                errors[field] = true;
                this.setState({ fields });
            }
        }else{
            fields[field] = e.target.value;
            errors[field] = false;
            this.setState({ fields });
        }
        
    }

    async addUser(data) {
        this.setState({ flagDisplay: false });
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }

        const api = process.env.REACT_APP_API_URL + 'LineGetUsers/';
        await axios.post(api, data, {
            headers: headers
        })
            .then(res => {
                //console.log(res.status);
                //console.log(res.data);
                let resData = res.data;
                if (res.status === 200) {
                    if (resData[0].status === 'fail') {
                        const errorMsg = resData[0].message;
                        //console.log(errorMsg.indexOf("Duplicate"));
                        let displayError = '';
                        if(errorMsg.indexOf("Duplicate") > -1){
                            displayError = 'Your phone number registered on the system. Please enter new phone number.'
                        }else{
                            displayError = resData[0].message
                        }
                        Swal.fire({
                            title: 'Oops...',
                            text: displayError,
                            type: 'error',
                            onClose: () => {
                                this.setState({ flagDisplay: true });
                            }
                        });
                    }else{
                        Swal.fire({
                            title: 'Success',
                            text: 'Thank you for register',
                            type: 'success',
                            onClose: () => {
                                this.closeApp();
                            }
                        });
                    }
                    
                } else {
                    Swal.fire({
                        title: 'Oops...',
                        text: 'Something went wrong! (Code: 200)',
                        type: 'error',
                        onClose: () => {
                            this.setState({ flagDisplay: true });
                        }
                    });
                }
            });
    }

    async updateUser(data) {
        this.setState({ flagDisplay: false });
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
        }

        const api = process.env.REACT_APP_API_URL + 'LineUpdateUsers/';
        await axios.post(api, data, {
            headers: headers
        })
            .then(res => {
                //Swal.fire('Oops...', 'Something went wrong!', 'error')
                let resData = res.data;
                if (res.status === 200) {
                    if (resData[0].status === 'fail') {
                        const errorMsg = resData[0].message;
                        let displayError = '';
                        if(errorMsg.indexOf("Duplicate") > -1){
                            displayError = 'Your phone number registered on the system. Please enter new phone number.'
                        }else{
                            displayError = resData[0].message
                        }
                        Swal.fire({
                            title: 'Oops...',
                            text: displayError,
                            type: 'error',
                            onClose: () => {
                                this.setState({ flagDisplay: true });
                            }
                        });
                    }else{
                        Swal.fire({
                            title: 'Success',
                            text: 'Thank you for register',
                            type: 'success',
                            onClose: () => {
                                this.closeApp();
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        type: 'error',
                        onClose: () => {
                            this.setState({ flagDisplay: true });
                        }
                    });
                }

                //console.log(res.status);
                //console.log(res.data);
            });
    }

    async getUsers() {
        const line_user_id = this.state.userId

        if (line_user_id !== '') {
            const api = process.env.REACT_APP_API_URL + 'LineGetUsers/' + line_user_id;
            //alert(api);
            await axios.get(api, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
            })
                .then(res => {
                    //console.log(res.data);
                    let userData = res.data;
                    //console.log(userData[0].status);

                    if (userData[0].status === 'fail') {
                        this.setState({
                            userType: 'new',
                            flagDisplay: true
                        });
                    } else {
                        var dateSelect = userData[0].customer_dob.split('-');
                        const yearSelect = dateSelect[0];
                        const monthSelect = dateSelect[1];
                        const daySelect = dateSelect[2];
                        const userProfile = userData[0]
                        //userProfile.push(yearSelect);

                        this.setState({
                            userType: 'current',
                            userData: userData[0],
                            fields: userProfile,
                            yearSelect: yearSelect,
                            monthSelect: monthSelect,
                            daySelect: daySelect,
                            flagDisplay: true
                        });

                        this.getAddress();
                    }

                    /*this.setState({
                        flagDisplay: true
                    });*/

                });
        }
    }

    async getAddress() {
        const userData = this.state.userData;
        const userToken = userData.customer_token;
        const api = process.env.REACT_APP_API_URL + 'GetAddress/' + userToken;
        //console.log(userData);
        await axios.get(api, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        })
            .then(res => {
                let userAddress = res.data;
                //console.log(userAddress);
                this.setState({
                    userAddress: userAddress
                });

            });
    }

    async addAddress() {
        const { value: text } = await Swal.fire({
            title: 'Please fill your address',
            input: 'textarea',
            inputPlaceholder: 'Type your message here...',
            inputAttributes: {
                'aria-label': 'Type your message here'
            },
            /*inputValue: 'asdasdasd',*/
            showCancelButton: true
        })

        if (text) {
            const userData = this.state.userData;
            const userToken = userData.customer_token;

            //Swal.fire(text);
            let data = {
                token: userToken,
                customer_shipping_address: text
            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }

            const api = process.env.REACT_APP_API_URL + 'GetAddress/';

            axios.post(api, data, {
                headers: headers
            }).then(res => {
                if (res.status === 200) {

                    let updateRes = res.data;
                    if (updateRes.length === 0) {
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {

                            }
                        });
                    }
                } else {

                    Swal.fire({
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        type: 'error',
                        onClose: () => {

                        }
                    });
                }
            });
            this.getAddress();
        }
    }

    async updateAddress(id, data) {
        const { value: text } = await Swal.fire({
            title: 'Please fill your address',
            input: 'textarea',
            inputPlaceholder: 'Type your message here...',
            inputAttributes: {
                'aria-label': 'Type your message here'
            },
            inputValue: data,
            showCancelButton: true
        })

        if (text) {
            const userData = this.state.userData;
            const userToken = userData.customer_token;

            //Swal.fire(text);
            let data = {
                token: userToken,
                customer_shipping_id: id,
                customer_shipping_address: text
            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }

            const api = process.env.REACT_APP_API_URL + 'UpdateAddress/';

            axios.post(api, data, {
                headers: headers
            }).then(res => {
                if (res.status === 200) {

                    let updateRes = res.data;
                    if (updateRes.length === 0) {
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {

                            }
                        });
                    }
                } else {

                    Swal.fire({
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        type: 'error',
                        onClose: () => {

                        }
                    });
                }
            });
            this.getAddress();
        }
    }

    async deleteAddress(id) {
        await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                /*Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )*/
                const userData = this.state.userData;
                const userToken = userData.customer_token;

                let data = {
                    token: userToken,
                    customer_shipping_id: id
                };
    
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
    
                const api = process.env.REACT_APP_API_URL + 'DeleteAddress/';

                axios.post(api, data, {
                    headers: headers
                }).then(res => {
                    if (res.status === 200) {
                        let updateRes = res.data;
                        if (updateRes.length === 0) {
                            Swal.fire({
                                title: 'Oops...',
                                text: 'Something went wrong!',
                                type: 'error',
                                onClose: () => {
    
                                }
                            });
                        }
                    } else {
    
                        Swal.fire({
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            type: 'error',
                            onClose: () => {
    
                            }
                        });
                    }
                });
                this.getAddress();
            }
        })
    }

    async initialize() {
        // liff.init(async (data) => {
        //     let profile = await liff.getProfile();
        //     this.setState({
        //         displayName: profile.displayName,
        //         userId: profile.userId,
        //         pictureUrl: profile.pictureUrl,
        //         statusMessage: profile.statusMessage,
        //         //flagDisplay: true
        //     });
        //     //alert(profile.userId);
        //     this.getUsers();
        // });
        const liffid = '1607959730-1knN4PyY';
        await liff.init({ liffId: `${liffid}` }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            let profile = await liff.getProfile();
            // this.setState({
            //     name: getProfile.displayName,
            //     userLineID: getProfile.userId,
            //     pictureUrl: getProfile.pictureUrl,
            // });
            this.setState({
                displayName: profile.displayName,
                userId: profile.userId,
                pictureUrl: profile.pictureUrl,
                statusMessage: profile.statusMessage,
                //flagDisplay: true
            });
            //alert(profile.userId);
            this.getUsers();
        }else{
            liff.login();
        }
    }

    closeApp(event) {
        /*liff.sendMessages([{
            type: 'text',
            text: this.state.userId
        }]).then(() => {
            liff.closeWindow();
        });*/
        if(this.state.callBackLink === ''){
            liff.closeWindow();
        }else{
            //history.push('/orders');
            this.setState({
                flagRedirect: true
            });
        }
        
    }

    DayofBirth() {
        const day = [];
        for (var i = 1; i <= 31; i++) {
            let dayFormat = i;
            if (dayFormat < 10) {
                dayFormat = '0' + dayFormat;
            }

            day.push(<option key={i} value={dayFormat}>{dayFormat}</option>);

        }
        return day;
    }

    MonthofBirth() {
        const month = [];
        for (var i = 1; i <= 12; i++) {
            let monthFormat = i;
            if (monthFormat < 10) {
                monthFormat = '0' + monthFormat;
            }
            
            month.push(<option key={i} value={monthFormat}>{monthFormat}</option>);
        }
        return month;
    }

    YearofBirth() {
        let currentYear = new Date().getFullYear();
        let startYear = currentYear - 80
        const year = [];
        for (var i = startYear; i <= currentYear; i++) {
            year.push(<option key={i} value={i}>{i}</option>);
        }
        return year;
    }

    render() {
        //alert('home');
        if(this.state.flagRedirect === true){
            let callBack = '/'+this.state.callBackLink;
            return <Redirect to={callBack} />
        }

        if (this.state.flagDisplay === true) {
            //alert('flag display');

            const fields = this.state.fields;

            var getBirthDay = new Date();
            if(typeof fields.customer_dob !== 'undefined'){
                getBirthDay = new Date(fields.customer_dob);
            }

            const BirthDay = getBirthDay.getDate() < 10 ? '0'+getBirthDay.getDate():getBirthDay.getDate();
            const BirthMonth = (getBirthDay.getMonth()+1) < 10 ? '0'+(getBirthDay.getMonth()+1):(getBirthDay.getMonth()+1);
            const BirthYear = getBirthDay.getFullYear();

            let headerTitle = 'Please sign up';
            let buttonName = 'Sign up';
            if(this.state.userType === 'current'){
                headerTitle = 'Update Profile';
                buttonName = 'Update';
            }

            let userAddress = this.state.userAddress;

            let AddressDetail = [];
            for (let i = 0; i < userAddress.length; i++) {
                AddressDetail.push(userAddress[i]);
            }

            let SetNewAddress = '';
            if (userAddress.length < 2) {
                SetNewAddress = 
                    <div>
                        <Button color="success" size="lg" block className="btnStyle" onClick={() => this.addAddress()}>Add Address</Button>
                    </div>
            }

            return (
                <div className="home">
                    <Header />

                    <div className="signup">

                        <h2 className="text-success">{headerTitle}</h2>
                        <hr />
                        <Form>
                            <FormGroup>
                                <Input invalid={this.state.errors.customer_fullname} type="text" name="customer_fullname" id="customer_fullname" placeholder="Full name" onChange={this.handleChange.bind(this, "customer_fullname")} defaultValue={this.state.fields["customer_fullname"]} />
                                <FormFeedback>Please enter your fullname</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input invalid={this.state.errors.customer_nickname} type="text" name="customer_nickname" id="customer_nickname" placeholder="Nickname" onChange={this.handleChange.bind(this, "customer_nickname")} defaultValue={this.state.fields["customer_nickname"]} />
                                <FormFeedback>Please enter your nickname</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input invalid={this.state.errors.customer_phone} type="text" name="customer_phone" id="customer_phone" placeholder="Phone number" onChange={this.handleChange.bind(this, "customer_phone")} defaultValue={this.state.fields["customer_phone"]} />
                                <FormFeedback>Please enter your mobile phone number</FormFeedback>
                            </FormGroup>
                            <h5 className="text-muted">Birthday</h5>
                            <Row form>
                                <Col sd={4}>
                                    <FormGroup>
                                        <Input invalid={this.state.errors.dob} type="select" name="dob" id="dob" onChange={this.handleChange.bind(this, "dob")} defaultValue={BirthDay}>
                                            {this.DayofBirth()}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sd={4}>
                                    <FormGroup>
                                        <Input invalid={this.state.errors.mob} type="select" name="mob" id="mob" onChange={this.handleChange.bind(this, "mob")} defaultValue={BirthMonth}>
                                            {this.MonthofBirth()}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sd={4}>
                                    <FormGroup>
                                        <Input invalid={this.state.errors.yob} type="select" name="yob" id="yob" onChange={this.handleChange.bind(this, "yob")} defaultValue={BirthYear}>
                                            {this.YearofBirth()}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Input invalid={this.state.errors.customer_sex} type="select" name="customer_sex" id="customer_sex" onChange={this.handleChange.bind(this, "customer_sex")} defaultValue={this.state.fields["customer_sex"]}>
                                    <option value='female'>Female</option>
                                    <option value='male'>Male</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Input invalid={this.state.errors.customer_weight} type="number" name="customer_weight" id="customer_weight" placeholder="Weight" onChange={this.handleChange.bind(this, "customer_weight")} defaultValue={this.state.fields["customer_weight"] === '0' ? '' : this.state.fields["customer_weight"]} />
                                <FormFeedback>Please enter your weight</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input invalid={this.state.errors.customer_height} type="number" name="customer_height" id="customer_height" placeholder="Height" onChange={this.handleChange.bind(this, "customer_height")} defaultValue={this.state.fields["customer_height"] === '0' ? '' : this.state.fields["customer_height"]} />
                                <FormFeedback>Please enter your height</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input invalid={this.state.errors.customer_activity} type="select" name="customer_activity" id="customer_activity" onChange={this.handleChange.bind(this, "customer_activity")} value={this.state.fields["customer_activity"]}>
                                    {activity_list}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input invalid={this.state.errors.customer_goal} type="select" name="customer_goal" id="customer_goal" onChange={this.handleChange.bind(this, "customer_goal")} value={this.state.fields["customer_goal"]}>
                                    {goal_list}
                                </Input>
                            </FormGroup>

                        </Form>
                        {
                            AddressDetail.map((item, key) => {
                                if(typeof item.customer_shipping_id === 'undefined'){
                                    return <div key='1'></div>
                                }else{
                                    return <div key={item.customer_shipping_id}>
                                        <br/>
                                        <h5 className="text-muted">Address {key+1}</h5>
                                        <FormGroup className="text-muted addressList">
                                            <Label check>
                                                {item.customer_shipping_address}
                                            </Label>
                                            
                                        </FormGroup>
                                        <Row>
                                            <Col>
                                                <Button color="success" size="sm" block className="btnStyle" onClick={() => this.updateAddress(item.customer_shipping_id, item.customer_shipping_address)}>Edit</Button>
                                            </Col>
                                            <Col>
                                                <Button color="success" size="sm" block className="btnStyle" onClick={() => this.deleteAddress(item.customer_shipping_id)}>Delete</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            })
                        }
                        
                        {SetNewAddress}

                        <Button color="success" size="lg" block className="btnStyle" onClick={this.handdleSignup}>{buttonName}</Button>
                    </div>
                </div>
            )
        } else {

            return (
                <div className="home">
                    <Header />

                    <div className="signup">
                        <Loading />
                    </div>
                </div>
            )
        }

    }
}

export default Home;
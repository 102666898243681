import React, { Component } from 'react';
import logo from '../../assets/logo-hd.png';
import './header.css';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <img src={logo} className="headerImg" alt="logo" />
            </div>
        )
    }
}

export default Header;
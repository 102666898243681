import React, { Component } from 'react';
import './loading.css';

class Loading extends Component {

    render() {

        return (
            <div className='Loading'>
                <div className="lds-css ng-scope">
                    <div style={{ width: '100%', height: '100%', display: 'inline-block'}} className="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )

    }
}

export default Loading;